import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { formatNumber, lblBr } from '../../Common/GlobalFunc';
import { getAllUpCalEntries } from './AddBet';
import { useWindowSize } from '../../Common/CommonHooks';
import { textFormatAmountFn  } from '../../Common/GlobalFunc';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { createPortal } from 'react-dom';
import { GlobalContext } from '../../../StandardPage';

const AllupDetailCalPanel = ({ isAllupCal, allupData, handleBetUnitChange,
    checkBetUnit, quickUnitBetChange, handleSelectFormula, forceAlertMsg, children, stickyBottom, isShow }) => {

    const { t } = useTranslation();
    const context = useContext(FootballContext);
    const { isMobileSize, width } = useWindowSize();
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        globalContext.setIsShowMobileFootballAllupCal((isShow || globalContext.isWaitMobileFootballAllupCal))
    }, [isShow, globalContext.isWaitMobileFootballAllupCal])

    const ValidationMessages = ({ amount, unitBet }) => {
        let hdcPools = context.content.config.HandicapOnlyPools;
        let message = '';
        let hasHDC = false;
        let selections = [];
        let legSize = 0;
        if ( isAllupCal ) {
            selections = getAllUpCalEntries();
            legSize = selections.length;
            hasHDC = selections.filter(x=> hdcPools.includes(x.betType)).length > 0;
        }
        else {
            selections = context.content.oddsSelection.filter(x => x.checked);
            legSize = [...new Set(selections.map(x=> x.matchId))].length;
            hasHDC = selections.filter(x=> hdcPools.some(y=> x.id.indexOf(y)>=0)).length > 0;
        }

        if ( forceAlertMsg && forceAlertMsg !== '' ) {
            message = forceAlertMsg;
        }
        else if (formatNumber(allupData.noOfComb * allupData.unitBet, 1, false, false, true) < 200 && hasHDC) {
            message = t('LB_FB_CAL_MIN_INV_ALERT')
        } else if (legSize == 1 && amount > 5000000) {
            message = t('LB_FB_CAL_MAX_AMOUNT_1_LEVEL_ALERT')
        } else if ((legSize == 2 || legSize == 3) && amount > 5000000) {
            message = t('LB_FB_CAL_MAX_AMOUNT_2_3_LEVEL_ALERT')
        } else if ((legSize == 4 || legSize == 5) && amount > 5000000) {
            message = t('LB_FB_CAL_MAX_AMOUNT_4_5_LEVEL_ALERT')
        } else if (legSize >= 6 && amount > 10000000) {
            message = t('LB_FB_CAL_MAX_AMOUNT_6_LEVEL_ALERT')
        }

        return message!=='' ? <div className='allup-cal-alert'>
            {message}
        </div> : null
    }

    const variableFontSize = (val) => {
        if ( isMobileSize ) {
            if ( val.length > 8 ) {
                return `${24 - val.length}px`;
            }
        }else if( width < 1100){
            if(val.length > 10){
                return `${24 - val.length}px`
            }
        }else if(width < 1279){
            if(val.length > 12){
                return `${27 - val.length}px`
            }
        }
        return width >= 1680 ? "17px" : "15px";
    }

    const allupFormulaItem = () => {
        let itemList = [];
        let alupCnt = allupData.alupFormula!='' ? parseInt(allupData.alupFormula.split('x')[0]) : 0;

        if (alupCnt <= 1) {
            return [];
        }

        let allupFormulaList = context.content.config.AllupFormula[alupCnt];

        allupFormulaList.map(item => {
            itemList.push(<Dropdown.Item
                eventKey={'formula-value-' + item}
                key={`formula-value-` + item}
                disabled={false}
                align='start'
                variant={item}
                title={item}
                value={item}
                onClick={() => handleSelectFormula(item)}  >
                {item}
            </Dropdown.Item>);
        })

        return itemList;
    }

    let tAmt = formatNumber(allupData.noOfComb * allupData.unitBet, 1, false, false, true);
    let div = formatNumber(allupData.dividend, 1, true, false, true);
    let netRtn = formatNumber(allupData.netReturn, 1, true, false, true);

    return stickyBottom ? document.getElementById('footbal-allup-detail-cal-panel') && createPortal(<div className={`allup-cal-panel-set sticky-bottom ${isShow || globalContext.isWaitMobileFootballAllupCal ? 'show' : 'hide'}`}>
        <div className="allup-cal-panel-btn">{children}</div>
    <div className="allup-cal-panel-input">
        <div className="allup-formula-dd">
            <div className='ddNoRSpace'>
                <DropdownButton
                    as={ButtonGroup}
                    key={`ddAllupFormula`}
                    id={`ddAllupFormula`}
                    drop='up'
                    align="start"
                    variant={allupData.alupFormula}
                    title={<><div>{allupData.alupFormula}</div><ArrowIcon direction='down' size={24}/></>}
                >
                    {allupFormulaItem()}
                </DropdownButton>
            </div>
        </div>
        <div className="allup-formula-input">
            <div>
                <span>{t('LB_FB_UNIT_BET')}</span>
                <input maxLength="10" type="text" inputMode="numeric" id="fbUnitBet" value={allupData.unitBet>=0 ? '$'+ textFormatAmountFn(allupData.unitBet, 0, false) : ''} onChange={handleBetUnitChange} onBlur={checkBetUnit} />
            </div>
        </div>
    </div>
    <div className="allup-cal-panel">
        <div className="allup-cal-panel-result">
            <div className="allup-cal-out">
                <div>{isMobileSize ? lblBr(t('LB_FB_NO_OF_BET_M')) : t('LB_FB_NO_OF_BET')}</div>
                <div>{allupData.noOfComb}</div>
            </div>
            <div className="allup-cal-out">
                <div>{isMobileSize ? lblBr(t('LB_FB_TOTAL_AMOUNT_M')) : t('LB_FB_TOTAL_AMOUNT')}</div>
                <div style={{fontSize: variableFontSize(tAmt)}}>{allupData.unitBet>=0 && allupData.noOfComb!='-' ? '$' + tAmt : '-'}</div>
            </div>
            <div className="allup-cal-out">
                <div>{isMobileSize ? lblBr(t('LB_FB_MAX_DIVIDEND_M')) : t('LB_FB_MAX_DIVIDEND')}</div>
                <div style={{fontSize: variableFontSize(div)}}>{allupData.unitBet>=0 && allupData.dividend!='-' ? '$' + div : '-'}</div>
            </div>
            <div className="allup-cal-out">
                <div>{isMobileSize ? lblBr(t('LB_FB_MAX_NET_RETURN_M')) : t('LB_FB_MAX_NET_RETURN')}</div>
                <div style={{fontSize: variableFontSize(netRtn)}}>{allupData.unitBet>=0 && allupData.netReturn!='-' ? <span className={`${allupData.netReturn > 0 ? 'net-return' : 'red'}`}>${netRtn}</span> : '-'}</div>
            </div>
        </div>
    </div>
    <ValidationMessages amount={allupData.dividend} unitBet={allupData.unitBet}/>
</div>, document.getElementById('footbal-allup-detail-cal-panel')) : <div className="allup-cal-panel-set">
        <div className="allup-cal-panel-input bottom-border">
            <div className="allup-formula-dd">
                <div>{t('LB_FB_ALL_UP_FORMULA')}</div>
                <div className='ddNoRSpace'>
                    <DropdownButton
                        as={ButtonGroup}
                        key={`ddAllupFormula`}
                        id={`ddAllupFormula`}
                        align="start"
                        variant={allupData.alupFormula}
                        title={<><div>{allupData.alupFormula}</div><ArrowIcon direction='down' size={24}/></>}
                    >
                        {allupFormulaItem()}
                    </DropdownButton>
                </div>
            </div>
            <div className="allup-formula-input">
                <div>{t('LB_FB_UNIT_BET')}</div>
                <div>
                    <input maxLength="10" type="text" inputMode="numeric" id="fbUnitBet" value={allupData.unitBet>=0 ? textFormatAmountFn(allupData.unitBet, 0, false) : ''} onChange={handleBetUnitChange} onBlur={checkBetUnit} />
                </div>
            </div>
            <div style={{width: "0px"}}></div>
            <div className="allup-cal-quick-btn-set">
                <div>
                    <div className="allup-cal-quick-btn" onClick={()=>{quickUnitBetChange(10)}}>+ $10</div>
                    <div className="allup-cal-quick-btn" onClick={()=>{quickUnitBetChange(100)}}>+ $100</div>
                    <div className="allup-cal-quick-btn" onClick={()=>{quickUnitBetChange(-10)}}>- $10</div>
                    <div className="allup-cal-quick-btn" onClick={()=>{quickUnitBetChange(-100)}}>- $100</div>
                </div>
            </div>
        </div>
        <div className="allup-cal-panel">
            <div className="allup-cal-panel-result">
                <div className="allup-cal-out">
                    <div>{isMobileSize ? lblBr(t('LB_FB_NO_OF_BET_M')) : t('LB_FB_NO_OF_BET')}</div>
                    <div>{allupData.noOfComb}</div>
                </div>
                <div className="allup-cal-out">
                    <div>{isMobileSize ? lblBr(t('LB_FB_TOTAL_AMOUNT_M')) : t('LB_FB_TOTAL_AMOUNT')}</div>
                    <div style={{fontSize: variableFontSize(tAmt)}}>{allupData.unitBet>=0 && allupData.noOfComb!='-' ? '$' + tAmt : '-'}</div>
                </div>
                <div className="allup-cal-out">
                    <div>{isMobileSize ? lblBr(t('LB_FB_MAX_DIVIDEND_M')) : t('LB_FB_MAX_DIVIDEND')}</div>
                    <div style={{fontSize: variableFontSize(div)}}>{allupData.unitBet>=0 && allupData.dividend!='-' ? '$' + div : '-'}</div>
                </div>
                <div className="allup-cal-out">
                    <div>{isMobileSize ? lblBr(t('LB_FB_MAX_NET_RETURN_M')) : t('LB_FB_MAX_NET_RETURN')}</div>
                    <div style={{fontSize: variableFontSize(netRtn)}}>{allupData.unitBet>=0 && allupData.netReturn!='-' ? <span className={`${allupData.netReturn > 0 ? 'net-return' : 'red'}`}>${netRtn}</span> : '-'}</div>
                </div>
            </div>
            <div className="allup-cal-panel-btn">{children}</div>
        </div>
        <ValidationMessages amount={allupData.dividend} unitBet={allupData.unitBet}/>
    </div>
}
export default AllupDetailCalPanel;