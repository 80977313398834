import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import GearLegend from './GearLegend'
import 'reactjs-popup/dist/index.css';
import { clickHorseLink, clickJockyLink, clickTrainerLink, RaceNote, ColourImage } from './RCOddsTableBase';
import { RacingContext } from '../../Home/RacingPage';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const getConfig = (betType) => {
    if (['HOME', 'WP'].includes(betType)) {
        return {
            colour: true,
            jockey: true,
            trainer: true,
            draw: true,
            wt: true,
            bodyWT: true,
            last6: true,
            rt: true,
            gear: true,
            trumpCard: true,
            prio: true,
            traPref: true,
            ageSex: false,
            bestTime: false,
            WFAA: false
        }
    }
    return {
        colour: true,
        jockey: true,
        trainer: true,
        draw: true,
        wt: true,
        bodyWT: true,
        last6: true,
        rt: true,
        gear: true,
        trumpCard: true,
        prio: true,
        traPref: true,
        ageSex: true,
        bestTime: true,
        WFAA: true
    }
}

function TableRow({ id, name, value, isImage, onClickHandle, isLocal }) {
    const { t, i18n } = useTranslation()
    return <div className="rc-odds-row-m">
        <div className='pr'><span>{name}</span></div>
        {
            name !== t('LB_RC_ODDS_TABLE_GEAR') &&
            <div className="rc-colour">
                {
                    isImage && value !== '-' ?
                        <ColourImage key={`colour_${value}`} isLocal={isLocal} code={value} />
                        :
                        onClickHandle ? <span id={id?id:''} onClick={onClickHandle} className={isLocal ? 'font-color-blue' : ''}>{value || '-'}</span> : <span>{value || '-'}</span>
                }
            </div>
        }
        {name === t('LB_RC_ODDS_TABLE_GEAR') &&
            <>
                {
                    value && value !== '-' ? <Popup
                        trigger={<div className="font-color-blue">{value}</div>}
                        className='race-gear-legend-popup'
                        modal
                        nested
                    >
                        {close => <GearLegend className='race-gear-legend-m' gearData={value} />}
                    </Popup> : <div>-</div>
                }
            </>
        }
    </div>
}

const RaceInfoDialog = (props) => {
    const { t, i18n } = useTranslation()
    const context = useContext(RacingContext);
    const [config, setConfig] = useState({})
    const { isLocal, isScratch, showStandby, showIntRating } = props.info
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        setConfig(getConfig(props.info.betType))
    }, [])

    const handleClickJKorTN = (isJockey) => {
        if (!isLocal || isScratch || showStandby) return
        if (isJockey) {
            clickJockyLink(t, props.info.jockyId)
            glassboxCustomEvent("To_JCRW", "Jockey_Info_" + context.content.page);
        }
        else {
            clickTrainerLink(t, props.info.trnId)
            glassboxCustomEvent('To_JCRW', 'Trainer_Info_' + context.content.page);
        }
    }

    const disableScrollBar = () => {
        document.body.style.overflow = "hidden";
    }
    const enableScrollBar = () => {
        document.body.removeAttribute('style');
    }

    useEffect(() => {
        const hashchange = (e) => {
            if(e?.oldURL?.includes('#RaceInfoDialog')) {
                setOpen(false)
            } else {
                if(window.activeProps.info.horName === props.info.horName) {
                    setOpen(true)
                }
            }
        }
        window.addEventListener("hashchange", hashchange);
        return () => {
            window.removeEventListener('hashchange', hashchange);
        };
    }, [])

    const handleOpen = () => {
        window.activeProps = props
        setOpen(true)
        if(location.hash !== '#RaceInfoDialog') {
            history.pushState({page: 'RaceInfoDialog'}, "", "#RaceInfoDialog");
        }
    }

    const handleClose = () => {
        setOpen(false)
        history.go(-1)
    }
    return (

        <Popup
            onOpen={disableScrollBar}
            onClose={enableScrollBar}
            trigger={<div className='table-arrow-m'> <ArrowIcon onClick={handleOpen} direction='right' size={24}/>
            </div>}
            modal
            nested
            open={open}
        >
                <div className="modal font-size-15">
                    <button className="close" onClick={handleClose}>
                        <ArrowIcon className='hueRotate' direction='left' size={40}/>
                    </button>
                    <div className="header">
                        <div>{t('LB_RACE_' + props.info.betType)}</div>
                    </div>
                    <div className="content">
                        <p className="font-color-color font-size-18">
                            {
                                isLocal ? <a onClick={() => {
                                    clickHorseLink(t, `${props.info.color}`)
                                    glassboxCustomEvent('To_JCRW', 'Horse_Info_' + context.content.page);
                                }} className="font-color-blue">
                                    {props.info.no}. {props.info.horName}
                                </a> : <div>{props.info.no}. {props.info.horName}</div>
                            }

                            {props.info.rcNote && context.content.isLogon && <RaceNote isLocal={isLocal}  horseId={props.info.horseId} />}
                        </p>
                        <div className="rc-odds-table-m">
                            {config.colour && <TableRow isLocal={isLocal} name={t('LB_RC_ODDS_TABLE_COLOUR')} value={showStandby ? '-' : props.info.color} isImage={true} />}
                            {config.jockey && <TableRow isLocal={isLocal} name={t('LB_RC_ODDS_TABLE_JOCKEY')} value={isScratch || showStandby ? '-' :props.info.jockyName + props.info.alw} onClickHandle={() => handleClickJKorTN(true)} />}
                            {config.trainer && <TableRow isLocal={isLocal} name={t('LB_RC_ODDS_TABLE_TRAINER')} value={isScratch ? '-' :props.info.trnName} onClickHandle={() => handleClickJKorTN()} />}
                            {config.draw && <TableRow isLocal={isLocal} name={t('LB_RC_ODDS_TABLE_DRAW')} value={isScratch || showStandby ? '-' : props.info.draw} />}
                            {config.wt && <TableRow isLocal={isLocal} name={t('LB_RC_ODDS_TABLE_WT_M')} value={isScratch ? '-' : props.info.handicapWT} />}
                            {config.bodyWT && <TableRow isLocal={isLocal} name={t("LB_RC_ODDS_TABLE_BODYWT_M")} value={isScratch ? '-' : props.info.bodyWT} />}
                            {config.last6 && <TableRow isLocal={isLocal} name={isLocal ? t('LB_RC_ODDS_TABLE_L6R') : t('LB_RC_ODDS_TABLE_L5R')} value={isScratch ? '-' : props.info.last6} />}
                            {config.rt && showIntRating && <TableRow isLocal={isLocal} name={t("LB_RC_ODDS_TABLE_INT_RTG_M")} value={isScratch ? '-' : props.info.intRating} />}
                            {config.rt && <TableRow isLocal={isLocal} name={isLocal ? t("LB_RC_ODDS_TABLE_RTG_M") : t("LB_RC_ODDS_TABLE_INT_RTG_M")} value={isScratch ? '-' : props.info.rating} />}
                            {config.gear && <TableRow isLocal={isLocal} name={t('LB_RC_ODDS_TABLE_GEAR')} value={isScratch ? '-' : props.info.gear} />}
                            {config.trumpCard && <TableRow isLocal={isLocal} name={t("LB_RC_ODDS_TABLE_TRUMPCARD_M")} value={props.info.tCard ? t('LB_RC_ODDS_TABLE_TRUMPCARD_YES') : t("LB_RC_ODDS_TABLE_TRUMPCARD_NO")} />}
                            {config.prio && <TableRow isLocal={isLocal} name={t("LB_RC_ODDS_TABLE_PRIO_M")} value={props.info.pRun ? t('LB_RC_ODDS_TABLE_PRIO_YES') : t('LB_RC_ODDS_TABLE_PRIO_NO')} />}
                            {config.traPref && <TableRow isLocal={isLocal} name={t("LB_RC_ODDS_TABLE_TRNPRE_M")} value={props.info.OrderOfTrnPre} />}
                        </div>
                    </div>
                </div>
        </Popup>
    );
}

export default RaceInfoDialog