import React, { useRef, useEffect, useState, createContext, useLayoutEffect } from 'react';

import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getProductFromURL } from './components/Common/GlobalFunc';
import Header from './components/Header/Header';
import LandingPage from './components/Home/LangingPage';
import RacingPage from './components/Home/RacingPage';
import FootballPage from './components/Home/FootballPage';
import MarksixPage from './components/Home/MarksixPage';
import Footer from './components/Footer/Footer';
import MobileBottomMenu from './components/Menu/MobileBottomMenu';
import BetSlip from './components/BetSlip/BetSlip';
import { useQuery } from '@apollo/client';
import { getFootballMenuQuery } from './components/Football/Data/DataQuery';
import { useCommonControlQuery } from './components/Home/Data/DataRefreshHooks';
import { useEarlySettlementTxnQuery } from './components/Home/Data/EarlySettlementTxnQueryHook';
import { useRacingNotesQuery } from './components/Racing/Data/RacingNotesHooks';
import { useWindowSize } from './components/Common/CommonHooks';
import { callWAReq } from './components/Common/utils/wa';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { fbMenuData } from './components/Home/Data/fbMenu';
import { isLogonHigh } from './components/BetSlip/LoginIBHooks';
import useMqttClient from './components/Common/hooks/useMqttClient';
import { useLoginLogout_IB } from './components/BetSlip/LoginIBHooks';
import { useLoginLogout } from './components/BetSlip/LoginHooks';
import { PopupAlert } from './components/Common/ui-components/PopupAlert';
import useGetFootballMenuData from './components/Football/Data/useGetFootballMenuData';
import useServerTime from './components/Common/hooks/useServerTime';

const CustomScrollbars = (props) => {
    // const { isMobileSize } = useWindowSize();
    return (
        <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={200}
            className="StandardPage-scrollbars"
            style={{ height: '100vh' }}
            autoHeight={false}
            // thumbMinSize={600}
            {...props}
        />
    );
};

export const GlobalContext = createContext();

function StandardPage({loginLogoutHook}) {
    const { i18n } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const racingPageFunc = useRef();
    const racingNotesData = useRacingNotesQuery();
    const [cCtrlLoading, cCtrlData] = useCommonControlQuery();
    const [fbMenuGqlVars, setFbMenuGqlVars] = useState(fbMenuData);
    const { sessionServerTimeRef } = useServerTime();
    const globalRef = useRef({
        isLoadingData: false,
        isSendingBet: false,
        enableESButton: true,
        allupBoxChecked: false
    });
    const [globalState, setGlobalState] = useState({
        betType: '',
        product: '',
        betslipState: 0,
        mtg: null,
        jtcPara: [],
        commonCtrl: {},
        commonCtrlLoading: true
    });
    const mqttClient = useMqttClient({globalState});
    const esHook = useEarlySettlementTxnQuery({mqttClient, globalState});
    const [isShowMobileBottomMenu, setIsShowMobileBottomMenu] = useState(true);
    const [bottomMenuAddSlipButtonDisable, setBottomMenuAddSlipButtonDisable] = useState(false);
    const [isWaitMobileFootballAllupCal, setIsWaitMobileFootballAllupCal] = useState(false);
    const [isShowMobileFootballAllupCal, setIsShowMobileFootballAllupCal] = useState(false);
    const [jumpFbHomePage, setJumpFbHomePage] = useState(null);
    const [cashoutInProcess, setCashoutInProcess] = useState(false);
    const [mobileAlertObj, setMobileAlertObj] = useState({
        isShow: false,
        alertMsg: ''
    });
    // const loginLogoutHook = window.globalConfig.IS_IB ? useLoginLogout_IB() : useLoginLogout();

    const { loadFootballMenuQuery } = useGetFootballMenuData(setFbMenuGqlVars);

    useEffect(() => {
        loadFootballMenuQuery(60, []);
    }, []);

    useEffect(() => {
        if (!cCtrlLoading) {
            racingNotesData.setGlobalEnable(cCtrlData.peNotes?.JCBWButtonEnabled);
            globalState.commonCtrl = cCtrlData.jcbwControl || {};
            globalState.commonCtrlLoading = cCtrlLoading;
            //console.log(cCtrlData.jcbwControl);
            if (cCtrlData.jcbwControl?.enableGlassBox) {
                document.head.appendChild(window.gbscript);
            }
            setGlobalState({ ...globalState });
        }
    }, [cCtrlLoading]);

    const navigate = useNavigate();
    useLayoutEffect(() => {
        const href = window.location.href;
        if (href.indexOf('/racing') > 0 || href.indexOf('/football') > 0 ) {
            if (window.location.href.split('/').length >= 6) {
                callWAReq();
            }
        } else {
            callWAReq();
        }
    }, [navigate]);

    const updateGlobalState = (args) => {
        // SQ0248-2160
        setGlobalState((globalState) => {
            return { ...globalState, ...args };
        });
        globalRef.current = {
            isSendingBet: args.isSendingBet != null ? args.isSendingBet : globalRef.current.isSendingBet,
            enableESButton: args.enableESButton != null ? args.enableESButton : globalRef.current.enableESButton,
            allupBoxChecked: args.allupBoxChecked != null ? args.allupBoxChecked : globalRef.current.allupBoxChecked
        };
    };

    const resetGlobalState = () => {
        setGlobalState({
            product: globalState.product,
            betType: globalState.betType,
            betslipState: globalState.betslipState,
            mtg: globalState.mtg,
            jtcPara: [],
            targetPage: '',
            commonCtrl: globalState.commonCtrl,
            commonCtrlLoading: globalState.commonCtrlLoading,
            cashoutData: globalState.cashoutData
        });
        globalRef.current = {
            isLoadingData: false,
            isSendingBet: false,
            enableESButton: true,
            allupBoxChecked: false
        };
    };

    const ToProductHomePage = ({ prod, lang }) => {
        let urlStr = `/${i18n.language}/${prod}/home`;
        if (prod === 'ewin') {
            urlStr = `/${i18n.language}/home`;
            if (lang && ['/en', '/ch'].includes(location.pathname)) {
                urlStr = `${location.pathname}/home`;
            }
        }
        navigate(urlStr);
        return null;
    };

    const { isShowCSLogoutDialog } = loginLogoutHook;

    if (window.globalConfig.IS_SPEEDBET && !isLogonHigh() && !isShowCSLogoutDialog) {
        return <Navigate to={`/${i18n.language}/login`} />;
    }
    if (window.globalConfig.IS_EWIN2 && !isLogonHigh() && !isShowCSLogoutDialog) {
        return <Navigate to={`/${i18n.language}/eWin2/login`} />;
    }

    return (
        <CustomScrollbars>
            <div className={`StandardPage ${getProductFromURL()}`}>
                <style>{`
                    .GL .icon-racingnote, .pcard .icon-racingnote{
                    background-image: url(${window.sitecoreVars.NOTES_ADD_BLACK_ICON});
                    }
                    .GL .icon-racingnoteUpdate, .pcard .icon-racingnoteUpdate{
                    background-image: url(${window.sitecoreVars.NOTES_UPD_ICON});
                    }
                    .icon-racingnote {
                        background-image: url(${window.sitecoreVars.NOTES_ADD_WHITE_ICON});
                    }
                    .icon-racingnoteUpdate{
                        background-image: url(${window.sitecoreVars.NOTES_UPD_WHITE_ICON});
                    }
                    .rc-note.add {
                        background-image: url(${window.sitecoreVars.NOTES_ADD_ICON});
                    }
                    .rc-note.update {
                        background-image: url(${window.sitecoreVars.NOTES_UPD_ICON});
                    }
                    .rc-note.fav {
                        background-image: url(${window.sitecoreVars.NOTES_FAV_ICON});
                    }
                `}</style>
                <GlobalContext.Provider
                    value={{
                        globalState,
                        globalRef,
                        updateGlobalState,
                        resetGlobalState,
                        racingPageFunc,
                        fbMenuGqlVars,
                        loadFootballMenuQuery,
                        racingNotesData,
                        isShowMobileBottomMenu,
                        setIsShowMobileBottomMenu,
                        isWaitMobileFootballAllupCal,
                        setIsWaitMobileFootballAllupCal,
                        isShowMobileFootballAllupCal,
                        setIsShowMobileFootballAllupCal,
                        bottomMenuAddSlipButtonDisable,
                        setBottomMenuAddSlipButtonDisable,
                        jumpFbHomePage,
                        setJumpFbHomePage,
                        cashoutInProcess,
                        setCashoutInProcess,
                        mqttClient,
                        loginLogoutHook,
                        mobileAlertObj,
                        setMobileAlertObj,
                        sessionServerTimeStamp:sessionServerTimeRef?.current,
                        esHook
                    }}
                >
                    <Header loginLogoutHook={loginLogoutHook} />
                    <div className="main-content">
                        <div className="left-content">
                            <Routes>
                                <Route path="/racing" element={<ToProductHomePage prod="racing" />} />
                                <Route path="/football" element={<ToProductHomePage prod="football" />} />
                                <Route path="/marksix" element={<ToProductHomePage prod="marksix" />} />
                                <Route
                                    path="/:lang/racing/:page/:date/:venue/:raceNo"
                                    element={<RacingPage ref={racingPageFunc} />}
                                />
                                <Route
                                    path="/:lang/racing/:page/:date/:venue"
                                    element={<RacingPage ref={racingPageFunc} />}
                                />
                                <Route path="/:lang/racing/:page" element={<RacingPage ref={racingPageFunc} />} />
                                <Route path="/:lang/racing" element={<RacingPage ref={racingPageFunc} />} />
                                <Route path="/:lang/football/:page/:matchId" element={<FootballPage />} />
                                <Route path="/:lang/football/:page" element={<FootballPage />} />
                                <Route path="/:lang/football" element={<FootballPage />} />
                                <Route path="/:lang/marksix/:page/:date" element={<MarksixPage />} />
                                <Route path="/:lang/marksix/:page" element={<MarksixPage />} />
                                <Route path="/:lang/marksix" element={<MarksixPage />} />
                                <Route path="/:lang/home" element={<LandingPage />} />
                                <Route path="/:lang" element={<ToProductHomePage prod="ewin" lang="lang" />} />
                                <Route path="*" element={<ToProductHomePage prod="ewin" />} />
                            </Routes>
                        </div>
                        <BetSlip product={globalState.product} useLoginLogout={loginLogoutHook} />
                    </div>
                    <Footer />
                    {isMobileSize && <MobileBottomMenu />}
                    {isMobileSize && <PopupAlert />}
                </GlobalContext.Provider>
            </div>
        </CustomScrollbars>
    );
}

export default StandardPage;
