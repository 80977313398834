import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import useCookie from '../Common/hooks/useCookie.js';


const LangSelector = () => {
  const { t, i18n } = useTranslation();
//   const [selectedLang, setSelectedLang] = useState(i18n.language);

  let navigate = useNavigate();

  let location = useLocation();

  const [language, setLanguage] = useCookie('language', 'ch', { expires: 365 * 2 });

  useEffect(() => {
    initLang();
  }, []);

  const initLang = () =>{

    const firstPath = location.pathname.substring(1, location.pathname.indexOf("/",2));
    const lang = firstPath === 'en' || firstPath === 'ch' ? firstPath : language ?? 'ch';

    // if(selectedLang !== lang){
    //   setSelectedLang(lang);
    //   i18n.changeLanguage(lang);
    // }

    setLanguage(lang)
    i18n.changeLanguage(lang);
    const html = document.querySelector('html');
    html.setAttribute('lang', lang === 'en' ? 'en' : 'zh-HK')
  }

  const changeLanguage = () => {

    let lang = t('LB_SWITCH_LANG_CODE');

    const url = window.location.pathname === "/" ? window.location.pathname + lang + "/" : window.location.pathname.replace("/"+language+"/", "/"+lang+"/")+window.location.search + window.location.hash;
    setLanguage(lang)
    const state = {...window.history.state}
    navigate(url, {state, replace: true});
    window.history.replaceState(state,'',)

    // setSelectedLang(lang);
    i18n.changeLanguage(lang);

    const html = document.querySelector('html');
    html.setAttribute('lang', lang === 'en' ? 'en' : 'zh-HK')
  }

  return (
      <div className="fMenuRowLang" onClick={changeLanguage}>
        <div className="topMenuIcon" />
        <span className="topMenuLang">{t('LB_SWITCH_LANG')}</span>
      </div>
  )
}

export default LangSelector;