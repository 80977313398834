import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate  } from 'react-router-dom';
import moment from 'moment';
import { EmptyTable } from '../../Common/EwinHomeCommon';
import MobileTabSet from '../../Common/MobileTabSet';
import { LandingContext } from '../../Home/LangingPage';
import { GlobalContext } from '../../../StandardPage';
import { useQuery } from '@apollo/client';
import { getMatchQuery } from '../Data/DataQuery';
import { toDateFormat, isSellingStarted } from './Common';
import { getDayOfWeekShort, NewWindow } from '../../Common/home-common';
import { errRedirectPage } from '../../Common/utils';
import { ClockIcon, isMatchKickOff, getInplayState } from './MatchBase';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { Query } from '../../Common/graphqlFetch'
import { gql } from '@apollo/client';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { scrollTo } from '../../Common/GlobalFunc';

const CustomScrollbars = (props) => {
    return <Scrollbars style={{ height: '100%' }} autoHeight={false} {...props} />
};

const EwinHomeFootballBlock = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const context = useContext(LandingContext);
    const globalContext = useContext(GlobalContext);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        Query({
            variables: {
                fbOddsTypes: ["HAD"],
                fbOddsTypesM: ["HAD"],
                inplayOnly:false,
                featuredMatchesOnly:false,
                startDate:null,
                endDate:null,
                tournIds:null,
                matchIds:null,
                frontEndIds:null,
                earlySettlementOnly: false
            },
            query: getMatchQuery(),
            disableErrorMsg: true
        }).then(result => {
            if(result.data){
                setData(result?.data)
                setLoading(false)
            }
        }).catch(error =>{
            //errRedirectPage(error)
        })
    },[])



    const [tabs, setTabs] = useState([
        { type: "F", name1: 'LB_FB_TITLE_OFM', disabled: false, checked: true },
        { type: "P", name1: '', disabled: true, checked: false },
        { type: "C", name1: '', disabled: true, checked: false }
    ]);

    const currentType = () =>{
        return tabs.filter(d => d.checked)[0].type;
    }

    const changeTab = (value) => {
        // let value = event.currentTarget.getAttribute("value");
        let oldType = currentType();
        if (oldType == value) {
            return;
        }
        for (let idx in tabs) {
            if (tabs.hasOwnProperty(idx)) {
                tabs[idx].checked = false;
                if (tabs[idx].type === value) {
                    tabs[idx].checked = true;
                }
            }
        }
        setTabs([...tabs]);
    }


    const ClickHereLink = ({lbl}) => {
        return <span className="blockFootballLink" onClick={()=>{ scrollTo(0, 0); navigate(`/${i18n.language}/football`) }}>{lbl}</span>
    }

    const FBNotOfferMsg = () => {
        return <div className="blockMsg2">
            <div>{t('LB_FB_INFO_NOOFFER1')}</div>
            <div>{t('LB_FB_INFO_NOOFFER2')}<ClickHereLink lbl={t('LB_FB_INFO_NOOFFER3')} />{t('LB_FB_INFO_NOOFFER4')}</div>
        </div>
    }

    const goToAllOddsPage = (match) => {
        let page = isMatchKickOff(match.status) ? 'inplay_all' : 'allodds';
        navigate(`/${i18n.language}/football/${page}/${match.id}`);
    }


    return (
        <section className="FBBlock">
            <div  role='button' tabIndex='0' className="blockHeader" onClick={()=>{ scrollTo(0, 0); navigate(`/${i18n.language}/football`) }}>
                <div>{t('LB_BMENU_FOOTBALL')}</div>
                <ArrowIcon className='hueRotate' direction='right' size={24}/>
            </div>

            <MobileTabSet className="fbTab" items={tabs} handleRadioClick={changeTab} />

            {loading && <EmptyTable/> }
            {!loading && currentType()==="F" && <FeaturedMatchesTable FBNotOfferMsg={FBNotOfferMsg} data={data} goToAllOddsPage={goToAllOddsPage} globalContext={globalContext}/>}

        </section>
    );
}
export default EwinHomeFootballBlock;

const FeaturedMatchesTable = ({FBNotOfferMsg, data, globalContext, goToAllOddsPage}) => {

    const {t, i18n} = useTranslation()

    let matches = data?.matches?.filter(match =>{
        const poolStartSell = match.foPools?.[0]?.status === 'SELLINGSTARTED'
        const isKickOff = isMatchKickOff(match.status)
        return !isKickOff && poolStartSell
    });
    let h2hEnabled = globalContext.globalState.commonCtrl?.headToHeadIconEnabled;
    if ( matches==null || matches.length===0 ) {
        return <FBNotOfferMsg />
    }

    return <div className="blockBody">
        <CustomScrollbars>
            <div className="blockFeaturedMatches">
                {matches.slice(0, 2).map(match=> {
                    let dt = match.kickOffTime.substring(0,10);
                    let time = moment(match.kickOffTime).utcOffset('+08:00').format("HH:mm");
                    let h2hUrl = window.globalConfig.STAT_CENTER_URL + t('URL_FB_H2H').replace('{0}', match.tournament.id).replace('{1}', match.id)
                    let isInplayPool = match.poolInfo.inplayPools.length>0;
                    return <>
                        <div className="blockMatchDate">{`${toDateFormat(dt, true, false)} (${getDayOfWeekShort(dt, t)}) ${t('LB_FB_MATCH')}`}</div>
                        <div className="right-border center">{time}</div>
                        <div className="right-border center">{match.frontEndId}</div>
                        <div className="right-border team" onClick={() => goToAllOddsPage(match)} >
                            <div title={match.homeTeam['name_' + i18n.language]}>{match.homeTeam['name_' + i18n.language]}</div>
                            <div title={match.awayTeam['name_' + i18n.language]}>{match.awayTeam['name_' + i18n.language]}</div>
                        </div>
                        <div className="center">{isInplayPool && <ClockIcon match={match} />}</div>
                        {h2hEnabled ? <div className='image-H2H image-H2H-item' onClick={() => NewWindow(h2hUrl, '', 770, 500, 1, 1) } title={t('LB_FB_H2H')}></div> : null}
                    </>
                })}
            </div>
        </CustomScrollbars>
    </div>
}