import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { GlobalContext } from '../../../StandardPage';
import { isInplayMatch, isInplayStage } from '../../Football/Common/MatchBase';
import FootballTitle from '../../Football/Common/FootballTitle';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const CashOutResultMeassage = ({ data }) => {
    const { t } = useTranslation()
    const globalContext = useContext(GlobalContext);
    const containerRef = useRef()

    useEffect(() => {
        if (data.id) {
            setTimeout(() => {
                globalContext.esHook.updateToastMsgs(data.id, '', '-')
            }, 3 * 1000)
        }
    }, [])

    let msgText = null
    switch(data.st){
        case "successful":
            msgText = 'LB_CASHOUT_ACCEPTED'
            break;
        case "rejected":
            msgText = 'LB_CASHOUT_REJECTED'
            break;
        case "value_rejected":
            msgText = 'LB_CASHOUT_VALUE_REJECTED'
            break;
        case "jsEarlySettlementRejected1":
            msgText = 'LB_CASHOUT_REJECTED1'
            break;
        case "jsEarlySettlementRejected2":
            msgText = 'LB_CASHOUT_REJECTED2'
            break;
        case "jsEarlySettlementRejected3":
            msgText = 'LB_CASHOUT_REJECTED3'
            break;
        case "jsEarlySettlementRejected4":
            msgText = 'LB_CASHOUT_REJECTED4'
            break;
        case "jsEarlySettlementRejected6":
            msgText = 'LB_CASHOUT_REJECTED6'
            break;
        default:
            break;
    }

    return <div ref={containerRef} className={`cashOutResultMeassage ${data.st}`}>
        {msgText && <><div className={`${data.st === "successful"?'icon-status-accepted':'icon-status-rejected'} cashOutMsgIcon`}></div> 
        <span className={`successfulText ${data.st === "successful"?'':'rejectColor'}`}>{t(msgText)}</span></>}
    </div>
}

export default CashOutResultMeassage

export const CashoutTitleUI = ({isLoading, handleClickBack}) => {
    const {t} = useTranslation()
    return <>
        <header>
            <FootballTitle title={t('LB_FB_TITLE_CASHOUT')} isPoolDefined={true} />
        </header>
        <div className={`fb-cashout-header ${isLoading ? 'cashoutInProcess' : ''}`}>
            <ArrowIcon className='hueRotate cashout-arrow-left' direction='left' onClick={handleClickBack} size={30} />
            {t('LB_FB_TITLE_CASHOUT')}
        </div>
    </>
}

export const CashOutMsgDialog = () => {
    const globalContext = useContext(GlobalContext);
    let betslipContainer = document.getElementById('betslip-container')
    let leftContent = document.querySelector('.main-content .left-content')
    let cashOutMsgMain = document.getElementsByClassName('cashOutMsgMain')[0]
    if (leftContent && cashOutMsgMain) {
        const width = leftContent.getBoundingClientRect().width + betslipContainer.getBoundingClientRect().width;
        cashOutMsgMain.style.width = width+'px';
    }
    return <div className='cashOutMsgMain'>
        {
            globalContext.esHook.esToastMsgs.map(item => {
                return <CashOutResultMeassage data={item} key={`${item.id}`} />
            })
        }
    </div>
}

export const CashOutTabComp = ({ tabsConfig, onClickTab, curTab, isLoading }) => {
    const tabSliderRef = useRef()
    const esTabsContainerRef = useRef()
    const { t } = useTranslation()
    useEffect(() => {
        if(tabSliderRef.current){
            let curYellowBarIndex=0;
            tabsConfig.map((item,index)=>{
                if( item.tabName===curTab){
                    curYellowBarIndex=index;
                }
            });
            const esTabsWidth = esTabsContainerRef.current.scrollWidth;
            const tabWidth = esTabsWidth / tabsConfig.length / esTabsContainerRef.current.clientWidth * 100;

            tabSliderRef.current.style.left = `${curYellowBarIndex * tabWidth}%`;
            tabSliderRef.current.style.width = `${tabWidth}%`;
        }
    }, [curTab])

    return <div className='cashoutTabs'>
        <div className='cashoutTabsContainer' ref={esTabsContainerRef}>
            {
                tabsConfig?.map((item, index) => {
                    return <div key={item.tabName}
                        onClick={(e) => !isLoading && onClickTab(item.tabName, e)}
                        className={`cashoutTab ${item.tabName === curTab ? 'cashoutTabActive' : isLoading ? 'opacity30' : ''}`}>
                        {t(`LB_CASHOUT_${item.tabName}`)}{item.count > 0 ? ` (${item.count})` : ''}
                    </div>
                })
            }
        </div>
        <div ref={tabSliderRef} className='tabSlider' ></div>
    </div>
}

export const CashOutAllBtnDialog = ({ showAllBtnDialog, onCancelDialog, onConfirmDialog }) => {
    const { t } = useTranslation()
    return <Popup open={showAllBtnDialog} className="logout-reminder cashout-reminder" closeOnDocumentClick={false}>
        <div className="cashout-reminder-container">
            <div className="cashout-reminder-message">{t('LB_CASHOUT_All_BTN_TIPS')}</div>
            <div className="button-box">
                <div className='cancelBtn' onClick={onCancelDialog}>{t('LB_BS_LOGIN_CANCEL')}</div>
                <div className='cancelBtn confirmBtn' onClick={onConfirmDialog}>{t('LB_BS_FT_CONFIRM')}</div>
            </div>
        </div>
    </Popup>
}

export const CashOutNoData = (props) => {
    const { t } = useTranslation()
    const { curTab, jumpUrl, esApiError, cashoutData, singleBetData, allupBetData, isShowAllupTab } = props;
    let msgText1 = 'LB_CASHOUT_NO_ALL_DATA'
    let msgText2 = ''
    let btnText = 'LB_CASHOUT_VIEW_ALL_DEAL'

    if(cashoutData?.length == 0){
        if (['ALL',"SINGLE","ALLUP"].includes(curTab)) {
            msgText1 = 'LB_CASHOUT_NO_BET_DATA1'
            msgText2 = 'LB_CASHOUT_NO_BET_DATA2'
        }
        btnText = 'LB_CASHOUT_VIEW_ALL_MATCH'
    }

    if(esApiError){
        msgText1 = 'LB_BS_LOGIN_ERROR_OTHER'
    }

    const handleClickUrl = () => {
        jumpUrl(curTab);
    }

    return <div className='CashOutNoData'>
        <div className='CashOutNoDataMsg'>
            <div>{t(msgText1)}</div>
            {msgText2 != '' && <div className='CashOutNoDataText'>{t(msgText2)}</div>}
        </div>
        <div className={`CashOutNoDataBtn`} onClick={()=> {handleClickUrl()}}>{t(btnText)}</div>
    </div>
}

export const CashOutLogoutUI = (props) => {
    const { t } = useTranslation()
    const { isMobileSize } = props;
    const globalContext = useContext(GlobalContext);

    const handleClickLogin = () => {
        globalContext.setIsShowMobileBottomMenu(false);
        document.getElementById('betslip-container').style.display = 'inherit';
        document.getElementById("divBetSlipNormal").style.left = "calc(100vw - 90vw)";
        document.getElementById("betslip-over-lay").style.left = "0";
        document.body.style.overflow = "hidden";
    }

    return <div className='CashOutNoData CashOutLogoutState'>
        <div className='icon-status-logout'></div>
        <div className='CashOutNoDataMsg'>{t('LB_CASHOUT_LOGOUT_TIPS')}</div>
        {isMobileSize && <div className='CashOutNoDataBtn' onClick={handleClickLogin}>{t('LB_SIGNIN')}</div>
        }
    </div>
}

export const CashOutErrorUI = (props) => {
    const { t } = useTranslation()
    const globalContext = useContext(GlobalContext);

    return <div className='CashOutNoData CashOutLogoutState'>
        <div className='CashOutNoDataMsg'>{t(globalContext.esHook.esErrorMsg)}</div>
    </div>
}
export const CashOutDisabledUI = (props) => {
    const { t } = useTranslation()

    return <div className='CashOutNoData CashOutLogoutState CashoutDisabled'>
        <div className='CashOutNoDataMsg' dangerouslySetInnerHTML={{__html: t('LB_CASHOUT_DISABLED_MSG')}}></div>
    </div>
}

export const parseESBetline = (line) => {
    let objs = [];
    let startPos = 0;
    while (true) {
        let idx = line.indexOf("*", startPos);
        let idx2 = line.indexOf("@", idx);
        let idx3 = -1;
        while (true) {
            let idx4 = line.indexOf("+", idx2 + 1);
            idx3 = line.indexOf("/", idx2 + 1);
            if (idx4 >= 0 && idx3 >= 0 && idx4 < idx3) {
                idx2 = line.indexOf("@", idx4);
            }
            else {
                break;
            }
        }
        let odds = "";
        if (idx3 >= 0) {
            let combLine = line.substring(idx + 1, idx3);
            objs.push(parseESCombs(combLine));
            startPos = idx3 + 1;
        }
        else {
            idx3 = line.indexOf(" $", idx2 + 1);
            let combLine = line.substring(idx + 1, idx3);
            objs.push(parseESCombs(combLine));
            break;
        }
    }

    return objs;
}

export const parseESCombs = (combLine) => {
    let startPos = 0;
    let combs = [];
    while (true) {
        let idx = combLine.indexOf("@", startPos);
        let idx2 = combLine.indexOf('+', idx);
        let sel = combLine.substring(startPos, idx);
        let odds = "";
        if (idx2 >= 0) {
            odds = combLine.substring(idx + 1, idx2);
            combs.push({ comb: sel, odds: odds });
            startPos = idx2 + 1;
        }
        else {
            odds = combLine.substring(idx + 1);
            combs.push({ comb: sel, odds: odds });
            break;
        }
    }
    return combs;
}

export const ESCombination = ({ item, legNo }) => {
    let objs = parseESBetline(item.betline);
    let combs = objs[item.legs[legNo].legOrder - 1];
    return combs.map((sel, sIdx) => {
        return <div><ESSingleCombination item={item} legNo={legNo} sel={sel} sIdx={sIdx} key={legNo}/></div>;
    });

}

export const ESSingleCombination = ({ item, legNo, sel, sIdx }) => {
    const { t, i18n } = useTranslation()
    let comb = sel.comb;
    let odds = sel.odds;
    let isIrrational = item.legs[legNo].combinations[sIdx].wageringStatus == "Irrational";
    switch (item.legs[legNo].betType) {
        case "HAD":
        case "ETHAD":
        case "FHAD":
        case "HHAD":
        case "HDC":
        case "ETHDC":
        case "FTS":
        case "NTS":
        case "ETNTS":
        case "OOE":
            comb = comb.replace("H", t('LB_BS_ADDTOSLIP_FB_H'))
                .replace("A", t('LB_BS_ADDTOSLIP_FB_A'))
                .replace("D", t('LB_BS_ADDTOSLIP_FB_D'))
                .replace("N", t('LB_BS_ADDTOSLIP_FB_N'))
                .replace("O", t('LB_BS_ADDTOSLIP_FB_O'))
                .replace("E", t('LB_BS_ADDTOSLIP_FB_E')) + "@" + odds;
            break;
        case "HFT":
            if (i18n.language == "ch") {
                comb = comb.replace(/H/g, t('LB_BS_ADDTOSLIP_FB_H'))
                    .replace(/A/g, t('LB_BS_ADDTOSLIP_FB_A'))
                    .replace(/D/g, t('LB_BS_ADDTOSLIP_FB_D')) + "@" + odds;
            }
            else {
                comb = comb + "@" + odds;
            }
            break;
        case "HILO":
        case "ETHILO":
        case "FHLO":
        case "CHLO":
        case "FCHLO":
        case "ETCHLO":
            comb = comb.replace("H", t('LB_BS_ADDTOSLIP_FB_HIGH'))
                .replace("L", t('LB_BS_ADDTOSLIP_FB_LOW'))
                .replace(/\+/g, '') + "@" + odds;
            break;
        case "CRS":
        case "ETCRS":
        case "FCRS":
            return <div className={isIrrational ?  "esDimmed" : ""}>{comb
                .replace("HOAN", t('LB_BS_ADDTOSLIP_FB_HOAN'))
                .replace("AOHN", t('LB_BS_ADDTOSLIP_FB_AOHN'))
                .replace("HOAS", t('LB_BS_ADDTOSLIP_FB_HOAS'))
                .replace("AOHS", t('LB_BS_ADDTOSLIP_FB_AOHS'))
                .replace("OHN", t('LB_BS_ADDTOSLIP_FB_OHN'))
                .replace("OAN", t('LB_BS_ADDTOSLIP_FB_OAN'))
                .replace("HO", t('LB_BS_ADDTOSLIP_FB_HO'))
                .replace("AO", t('LB_BS_ADDTOSLIP_FB_AO'))
                .replace("DO", t('LB_BS_ADDTOSLIP_FB_DO'))
                 + "@" + odds}</div>;
        case "TTG":
        case "ETTTG":
            return <div className={isIrrational ?  "esDimmed" : ""}>{comb + "@" + odds}</div>;
        case "FGS":
            return `${item.legs[legNo].combinations[sIdx].comb?.str === "000" ? "00" : item.legs[legNo].combinations[sIdx].comb?.selections[0].str} ${item.legs[legNo].combinations[sIdx].comb?.selections[0]['name_'+i18n.language]}@${odds}`;
        case "SGA":
            return `${item.legs[legNo].pool?.instNo}. ${item.legs[legNo].combinations[sIdx].comb?.selections[0]['name_'+i18n.language]}@${odds}`;
        case "MSPC":
            return <>
                {`${item.legs[legNo].pool?.instNo}: ${item.legs[legNo].pool?.['name_'+i18n.language]}`}
                <br/>
                {`${item.legs[legNo].combinations[sIdx].comb?.selections[0].str}. ${item.legs[legNo].combinations[sIdx].comb?.selections[0]['name_'+i18n.language]}@${odds}`}
                </>;
    }
    return comb;
}

export const haveKickoffESMatch = (item) => {
    for (let idx in item.legs) {
        if (item.legs.hasOwnProperty(idx)) {
            let match = item.legs[idx].match;
            if (isInplayMatch(match) && isInplayStage(match)) {
                return true;
            }
        }
    }
    return false;
}