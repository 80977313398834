import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../Home/FootballPage';
import { RacingContext } from '../Home/RacingPage';
import { MarksixContext } from '../Home/MarksixPage';
import { GlobalContext } from '../../StandardPage';
import { isLocalMeeting } from '../Common/home-common';
import { highLightLeftMenuItem, LeftMenuIcon, hasChpTypePool } from './MenuCommon';
import { sortSeq, isEarlySettlementEanbled } from '../Football/Common/Common';
import { useOddsMenuQuery } from '../Home/Data/SitecoreDataHooks';
import Loading from '../Common/ui-components/Loading';
import { isLogonHigh } from '../BetSlip/LoginHooks'

const LeftMenu = (props) => {
    const { t, i18n } = useTranslation();
    const racingContext = useContext(RacingContext);
    const marksixContext = useContext(MarksixContext);
    const fbContext = useContext(FootballContext);
    const globalContext = useContext(GlobalContext);
    const fbMenuGqlVars = globalContext.fbMenuGqlVars;
    const product = props.Product;
    const [menuLoading, menuData, menuError] = useOddsMenuQuery(props.Product);
    const cashoutData = globalContext.esHook.getEsData() || [];

    const handleMenuClick = (menu) => {
        if(menu.itemRedirection.value===window.sitecoreVars.REDIRECT_JCBW_PAGE) {
            innerPageChange(menu.itemPage.value);
        }
        else {
            window.open(menu.itemLink.value, "_self");
        }
    }

    const innerPageChange = (page) => {
        let url = "";
        switch (product) {
            case 'racing':
                url  =`/${i18n.language}/${product}/${page}/${racingContext.content.date}/${racingContext.content.venue}/${racingContext.content.raceNo}$`;
                break;
            case 'football':
                url = `/${i18n.language}/${product}/${page}`;
                break;
            case 'marksix':
                url = `/${i18n.language}/${product}/${page}`;
                break
            default:
                break;
        }
        props.onPageChange(url, page);
    }

    const appendMenuNameSuffix = (menu) => {
        switch ( menu.key ) {
            case window.sitecoreVars.MENU_JKC_ODDS_PAGE:
                if ( racingContext?.content?.meeting?.jkcInstNo.length>0 ) {
                    return ` ${racingContext?.content?.meeting?.jkcInstNo[0].instNo}`;
                }
                break;
            case window.sitecoreVars.MENU_TNC_ODDS_PAGE:
                if ( racingContext?.content?.meeting?.tncInstNo.length>0 ) {
                    return ` ${racingContext?.content?.meeting?.tncInstNo[0].instNo}`;
                }
                break;
        }
        return '';
    }

    const getMenu = (menu, i, addedTournPId) => {
        let showMenu = menu.key!=window.sitecoreVars.MENU_EARLY_SETTLEMENT || isEarlySettlementEanbled(globalContext);
        if ( menu.itemEnable.value!=="1" || !showMenu )  {
            return null;
        }
        let menuList = [];
        let _name = menu.itemName.value;
        _name = _name.replace(/(\/)/g,'$1<wbr />')
        _name = <><span dangerouslySetInnerHTML={{ __html: _name }}></span>{appendMenuNameSuffix(menu)}</>
        let _url = menu.itemPage.value;
        let showChildItem = false;
        let predefinedColor = menu.predefinedColor?.targetItem?.optionValue?.value || "";
        let hlStyle = predefinedColor!="" ? { color: predefinedColor } : {};
        const isESDisabled = globalContext.globalState.commonCtrl.enableEarlySettlementUIMessage

        if ( menu.key===window.sitecoreVars.MENU_AUTO_CWA_MENU && globalContext.globalState.commonCtrl.CWAButtonEnabled ) {
            let mtg = racingContext?.content?.meeting;
            if ( mtg!=null ) {
                if ( isLocalMeeting(mtg.venueCode) || mtg.poolInvs.filter(x=> x.oddsType==="CWA").length > 0 ) {
                    menuList.push(<li key={menu.key} style={hlStyle}>{_name}<LeftMenuIcon menu={menu} /></li>);
                    showChildItem = true;
                }
            }
        }
        else if ( menu.key===window.sitecoreVars.MENU_AUTO_CWB_CWC_MENU ) {
            let mtg = racingContext?.content?.meeting;
            if ( mtg!=null ) {
                let hasCWB = mtg.poolInvs.filter(x=> "CWB"===x.oddsType).length > 0 && globalContext.globalState.commonCtrl.CWBButtonEnabled;
                let hasCWC = mtg.poolInvs.filter(x=> "CWC"===x.oddsType).length > 0 && globalContext.globalState.commonCtrl.CWCButtonEnabled;
                if ( hasCWB || hasCWC ) {
                    menuList.push(<li key={menu.key} style={hlStyle}>{_name}</li>);
                    showChildItem = true;
                }
            }
        }
        else if (menu.key===window.sitecoreVars.MENU_AUTO_FEATURED_MATCHES ) {
            if ( fbMenuGqlVars?.data?.featuredMatches?.length > 0 && globalContext.globalState.commonCtrl.enableFeaturedMatches ) {
                menuList.push(<li id={_url} key={menu.key} value={_url} className={`cursor-pointer ${highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''}`} style={hlStyle} onClick={()=> {handleMenuClick(menu)}}>
                    {_name}
                    <LeftMenuIcon menu={menu} />
                </li>);
            }
        }
        else if (menu.key===window.sitecoreVars.MENU_AUTO_TOURN_LIST) {
            fbMenuGqlVars?.data?.tournaments?.sort(sortSeq);
            fbMenuGqlVars?.data?.tournaments?.forEach(item=> {
                let isActiveMenuItem = window.location.pathname.indexOf('/'+item.nameProfileId) >= 0;
                let tournUrl = 'tournament/' + item.nameProfileId;
                let topItem = <li id={tournUrl} key={`menu-torunament-${item.nameProfileId}`} value={tournUrl} className={`cursor-pointer ${isActiveMenuItem ? 'active' : ''}`} style={hlStyle} onClick={()=> {innerPageChange(tournUrl)}}>
                    {item["name_"+i18n.language]}
                    <LeftMenuIcon menu={menu} />
                </li>
                if ( !addedTournPId.includes(item.nameProfileId) ) {
                    addedTournPId.push(item.nameProfileId);
                    menuList.push(topItem);
                }
            });
        }
        else if (menu.key===window.sitecoreVars.MENU_AUTO_FINALIST) {
            if ( hasChpTypePool("3", fbMenuGqlVars?.data?.chpTypes) ) {
                menuList.push(<li id={_url} key={menu.key} value={_url} className={`cursor-pointer ${highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''}`} style={hlStyle} onClick={()=> {handleMenuClick(menu)}}>
                    {_name}
                    <LeftMenuIcon menu={menu} />
                </li>);
            }
        }
        else if (menu.key===window.sitecoreVars.MENU_AUTO_WINNING_COUNTRY) {
            if ( hasChpTypePool("1", fbMenuGqlVars?.data?.chpTypes) ) {
                menuList.push(<li id={_url} key={menu.key} value={_url} className={`cursor-pointer ${highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''}`} style={hlStyle} onClick={()=> {handleMenuClick(menu)}}>
                {_name}
                <LeftMenuIcon menu={menu} />
            </li>);
            }
        }
        else if (menu.key===window.sitecoreVars.MENU_AUTO_WINNING_CONTINENT) {
            if ( hasChpTypePool("2", fbMenuGqlVars?.data?.chpTypes) ) {
                menuList.push(<li id={_url} key={menu.key} value={_url} className={`cursor-pointer ${highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''}`} style={hlStyle} onClick={()=> {handleMenuClick(menu)}}>
                {_name}
                <LeftMenuIcon menu={menu} />
            </li>);
            }
        }
        else {
            if (menu.specialtag?.value===window.sitecoreVars.MENU_ADHOC_TOURN) {
                let frontEndId = menu.frontendID.value;
                let tourn = fbContext?.contentRef.current.rawTournList.filter(x=>x.frontEndId===frontEndId)[0];
                if ( tourn ) {
                    addedTournPId.push(tourn.nameProfileId);
                    let isActiveMenuItem = window.location.pathname.indexOf('/'+tourn.nameProfileId) >= 0;
                    let tournUrl = "tournament/" + tourn.nameProfileId;
                    menuList.push(<li id={tournUrl} key={`menu-torunament-${tourn.nameProfileId}`} value={tournUrl} className={`cursor-pointer ${isActiveMenuItem ? 'active' : ''}`} style={hlStyle} onClick={()=> {innerPageChange(tournUrl)}}>
                        {_name}
                        <LeftMenuIcon menu={menu} />
                    </li>);
                }
            }
            else if (menu.specialtag?.value===window.sitecoreVars.MENU_ADHOC_MATCH) {
                let frontEndId = menu.frontendID.value;
                let match = fbContext.contentRef.current.rawMatchList.filter(x=>x.frontEndId===frontEndId)[0];
                if ( match ) {
                    let alloddsUrl = (match.status!=="PREEVENT" ? "inplay_all/" : "allodds/") + match.id;
                    menuList.push(<li id={alloddsUrl} key={`menu-torunament-${match.id}`} value={alloddsUrl} className={`cursor-pointer ${highLightLeftMenuItem(alloddsUrl, globalContext, product) ? 'active' : ''}`} style={hlStyle} onClick={()=> {innerPageChange(alloddsUrl)}}>
                        {_name}
                        <LeftMenuIcon menu={menu} />
                    </li>);
                }
            }
            else {
                menuList.push(<li id={_url} key={menu.key} value={_url} className={`cursor-pointer ${highLightLeftMenuItem(_url, globalContext, product) ? 'active' : ''}`} style={hlStyle} onClick={()=> {handleMenuClick(menu)}}>
                    {_name}
                    <LeftMenuIcon menu={menu} 
                    cashOutNo={
                        menu.key===window.sitecoreVars.MENU_EARLY_SETTLEMENT && isLogonHigh() && cashoutData.length>0 && !isESDisabled? 
                         <span className={`cashoutMenuNo${cashoutData.length>99?' moreThan99':''}`}>{cashoutData.length>99?'99+':cashoutData.length}</span>
                         : null
                     }
                    />
                </li>);
            }
        }


        if (showChildItem && menu.children?.length > 0 ) {
            let _childMenu = menu.children.map((cMenu, i) => {
                if ( cMenu.itemEnable.value!=="1" )  {
                    return null;
                }

                let _childName = cMenu.itemName.value;
                let _childUrl = cMenu.itemPage.value;
                let showMenu = false;
                let cHlCss= cMenu?.highlightInRed?.value==="1" ? "hlMenuText" : "";

                if ( cMenu.key===window.sitecoreVars.MENU_AUTO_CWB_MENU && globalContext.globalState.commonCtrl.CWBButtonEnabled ) {
                    let mtg = racingContext.content.meeting;
                    if ( mtg!=null ) {
                        showMenu = racingContext.content.meeting.poolInvs.filter(x=> "CWB"===x.oddsType).length > 0;
                    }
                }
                else if ( menu.key===window.sitecoreVars.MENU_AUTO_CWC_MENU && globalContext.globalState.commonCtrl.CWCButtonEnabled ) {
                    let mtg = racingContext.content.meeting;
                    if ( mtg!=null ) {
                        showMenu = racingContext.content.meeting.poolInvs.filter(x=> "CWC"===x.oddsType).length > 0;
                    }
                }
                else {
                    showMenu = true;
                }

                return showMenu ? <div key={cMenu.key} id={_childUrl} value={_childUrl} className={`son-menu cursor-pointer ${cHlCss} ${highLightLeftMenuItem(_childUrl, globalContext, product) ? 'active' : ''}`} onClick={()=> {handleMenuClick(cMenu)}}>
                    <span className="leftMenuItemPoint">{_childName}</span>
                    <LeftMenuIcon menu={cMenu} />
                </div> : null;
            });
            menuList.push(<div key={`menu-ol-${i}`}>{_childMenu}</div>);
        }

        return menuList;
    }

    

    const MenuHeader = () => {
        switch ( props.Product ) {
            case "racing":
            case "football":
                return <div className="leftMenu-top">{t("LB_ODDS")}</div>
        }
        return null;
    }

    const menuBodyProps ={
        getMenu, menuData, marksixContext
    }

    if (menuError && location.host !== 'localhost') {
        return <div>Error: { String(menuError) }</div>;
    } else if (menuLoading) {
        return <div className="leftMenuMain"><Loading /></div>;
    } else {
        return (
            <div className="leftMenuMain">
                <MenuHeader />
                <ul><MenuBody {...menuBodyProps}/></ul>
            </div>
        );
    }

}
export default LeftMenu;

const MenuBody = ({getMenu, menuData, marksixContext}) => {
    let addedTournPId = [];
    return menuData.map((menu, i) => {
        if ( menu.sectionTitle===null ) {
            return getMenu(menu, i, addedTournPId);
        }
        else {
            let showMenu = false;
            if ( menu.sectionEnabled?.value!=="1" ) {
                return null;
            }
            else if ( menu.key===window.sitecoreVars.MENU_AUTO_NEXT_DRAW ) {
                let draws = marksixContext.gqlVars.data?.lotteryDraws || [];
                let nextDraw = draws?.length > 1 ? draws[1] : null;
                if ( !nextDraw || marksixContext.isMergeDraw || !nextDraw?.snowballCode ) {
                    showMenu = true;
                }
            }
            else if ( menu.key===window.sitecoreVars.MENU_AUTO_SNOWBALL_DRAW ) {
                let draws = marksixContext.gqlVars.data?.lotteryDraws || [];
                let hasSbDraw = draws?.length > 2;
                if ( hasSbDraw ) {
                    showMenu = true;
                }
            }
            else {
                showMenu = true;
            }
            return showMenu ? <>
                <div className="leftMenu-top">
                    {menu.sectionTitle.value}
                    <LeftMenuIcon menu={menu}
                    />
                </div>
                <ul>
                    {menu.children?.map((cMenu, j) => {
                        return getMenu(cMenu, j, addedTournPId);
                    })}
                </ul>
            </> : null;
        }
    });
}