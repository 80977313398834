import React from 'react';
import { useTranslation } from 'react-i18next';

const MediaBanner = () => {
    const { t } = useTranslation();

    return (
        <div className="footer-banner-media-container">
            <a className="footer-banner-media" href={t('URL_MCS')} target="_blank" rel="noopener noreferrer">
                <div className="footer-banner-media-area" >
                    <div className="footer-banner-media-icon" ></div>
                </div>
                <div className="footer-banner-media-content">{t('LB_MEDIA_COMMUNICATION_SYSTEM')}</div>
            </a>
        </div>
    );
};
export default MediaBanner;
