import { cookie2obj } from '../../Common/utils';
import Cookies from 'js-cookie';

export const settings = {
    racing: [
        { key: 'WIN', min: 10, default: 10, lang: 'LB_WIN', betType: 'WIN' },
        { key: 'PLA', min: 10, default: 10, lang: 'LB_PLA', betType: 'PLA' },
        { key: 'W-P', min: 10, default: 10, lang: 'LB_RACE_BET_W-P', betType: 'W-P' },
        { key: 'QIN', min: 10, default: 10, lang: 'LB_QIN', betType: 'QIN' },
        { key: 'QPL', min: 10, default: 10, lang: 'LB_QPL', betType: 'QPL' },
        { key: 'QQP', min: 10, default: 10, lang: 'LB_BS_SET_QQP', betType: 'QQP' },
        { key: 'CW', min: 10, default: 10, lang: 'LB_CWIN', betType: 'CWA' },
        { key: 'IWN', min: 100, default: 100, lang: 'LB_IWN', betType: 'IWN' }, //
        { key: 'FCT', min: 10, default: 10, lang: 'LB_FCT', betType: 'FCT' }, //
        { key: 'TCE', min: 1, default: 10, lang: 'LB_TCE', betType: 'TCE' }, //
        { key: 'TRI', min: 1, default: 10, lang: 'LB_TRI', betType: 'TRI' }, //
        { key: 'FF', min: 1, default: 10, lang: 'LB_F-F', betType: 'FF' }, // FF
        { key: 'QTT', min: 1, default: 10, lang: 'LB_QTT', betType: 'QTT' }, //
        { key: 'DBL', min: 1, default: 10, lang: 'LB_DBL', betType: 'DBL' }, //
        { key: 'TBL', min: 1, default: 10, lang: 'LB_TBL', betType: 'TBL' }, //
        { key: 'DT', min: 1, default: 10, lang: 'LB_DT', betType: 'DT' }, // DT
        { key: 'TT', min: 1, default: 10, lang: 'LB_TT', betType: 'TT' }, // TT
        { key: '6UP', min: 1, default: 10, lang: 'LB_6UP', betType: '6UP' }, //
        { key: 'JKC', min: 10, default: 10, lang: 'LB_JKC', betType: 'JKC' }, //
        { key: 'TNC', min: 10, default: 10, lang: 'LB_TNC', betType: 'TNC' }, //
        { key: 'ALUP', min: 1, default: 10, lang: 'LB_RACE_CROSS_ALUP', betType: '' } //
    ],
    // TSP is MSP
    // MSP is SPC
    football: [
        { key: 'ALUP', min: 10, default: 10, lang: 'LB_RACE_CROSS_ALUP', betType: '' },
        { key: 'SGA', min: 10, default: 10, lang: 'LB_FB_TITLE_SGA', betType: 'SGA' },
        { key: 'HAD', min: 10, default: 10, lang: 'LB_FB_TITLE_HAD', betType: 'HAD' },
        { key: 'TQL', min: 10, default: 10, lang: 'LB_FB_TITLE_TQL', betType: 'TQL' },
        { key: 'FHA', min: 10, default: 10, lang: 'LB_FB_TITLE_FHA', betType: 'FHA' },
        { key: 'HHA', min: 10, default: 10, lang: 'LB_FB_TITLE_HHA', betType: 'HHA' },
        { key: 'HDC', min: 200, default: 200, lang: 'LB_FB_TITLE_HDC', betType: 'HDC' },
        { key: 'HIL', min: 10, default: 10, lang: 'LB_FB_TITLE_HIL', betType: 'HIL' },
        { key: 'FHL', min: 10, default: 10, lang: 'LB_FB_TITLE_FHL', betType: 'FHL' },
        { key: 'CHL', min: 10, default: 10, lang: 'LB_FB_TITLE_CHL', betType: 'CHL' },
        { key: 'FCH', min: 10, default: 10, lang: 'LB_FB_TITLE_FCH', betType: 'FCH' },
        { key: 'NTS', min: 10, default: 10, lang: 'LB_FB_TITLE_NTS', betType: 'NTS' },
        { key: 'CRS', min: 10, default: 10, lang: 'LB_FB_TITLE_CRS', betType: 'CRS' },
        { key: 'FCS', min: 10, default: 10, lang: 'LB_FB_TITLE_FCS', betType: 'FCS' },
        { key: 'FTS', min: 10, default: 10, lang: 'LB_FB_TITLE_FTS', betType: 'FTS' },
        { key: 'TTG', min: 10, default: 10, lang: 'LB_FB_TITLE_TTG', betType: 'TTG' },
        { key: 'OOE', min: 10, default: 10, lang: 'LB_FB_TITLE_OOE', betType: 'OOE' },
        { key: 'FGS', min: 10, default: 10, lang: 'LB_FB_TITLE_FGS', betType: 'FGS' },
        { key: 'HFT', min: 10, default: 10, lang: 'LB_FB_TITLE_HFT', betType: 'HFT' },
        { key: 'MSP', min: 10, default: 10, lang: 'LB_FB_TITLE_MSP', betType: 'MSP' },
        { key: 'GPW', min: 10, default: 10, lang: 'LB_FB_TITLE_GPW', betType: 'GPW' },
        { key: 'GPF', min: 10, default: 10, lang: 'LB_FB_TITLE_GPF', betType: 'GPF' },
        { key: 'TPS', min: 10, default: 10, lang: 'LB_FB_TITLE_TPS', betType: 'TPS' },
        { key: 'CHP', min: 10, default: 10, lang: 'LB_FB_TITLE_CHP', betType: 'CHP' }
    ],
    m6MulDrow: '1',
    accountInfo: {
        accountName: '0', // 0 show 1 hide
        accountBalance: '0' // 0show 1 hide
    },
    allUpSetting: '1', // 1 keep 2 delete
    racingNotes: '1', // 1 show 0 hide
    showBetlineDetail: '0' // 1 on 0 off
};

const racing = settings.racing.reduce((acc, item) => {
    acc[item.key] = item.default;
    return acc;
}, {});

const football = settings.football.reduce((acc, item) => {
    acc[item.key] = item.default;
    return acc;
}, {});

const defaultValue = { ...settings, racing, football };

export const eMap = {
    ECH: 'CHL',
    ECS: 'CRS',
    EDC: 'HDC',
    EHA: 'HAD',
    EHL: 'HIL',
    ENT: 'NTS',
    ETG: 'TTG'
};
export const cwExtend = ['CWA', 'CWB', 'CWC'];
export const alupExtend = ['CROSS_ALUP', 'WPALUP', 'CWAALUP', 'WPQALUP', 'FCTALUP', 'TRIALUP', 'CWAALLALUP'];

export function getPersonalSettings(path, subPath) {
    let personalSettings = Cookies.get('personalSettings');
    personalSettings = personalSettings ? cookie2obj(personalSettings) : defaultValue;
    if (path === 'football') {
        personalSettings.football = {
            ...personalSettings.football,
            ...Object.keys(eMap).reduce((acc, key) => {
                acc[key] = personalSettings.football[eMap[key]];
                return acc;
            }, {})
        };
        personalSettings.football.TSP = personalSettings.football.MSP;
    }
    if (path === 'racing') {
        cwExtend.forEach((i) => {
            personalSettings.racing[i] = personalSettings.racing.CW;
        });
        alupExtend.forEach((i) => {
            personalSettings.racing[i] = personalSettings.racing.ALUP;
        });
    }
    return subPath ? personalSettings?.[path]?.[subPath] : personalSettings?.[path];
}

export default defaultValue;
