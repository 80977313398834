import React, { useEffect, useState, useContext, createContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Banner from '../Header/Banner';
import ImportantNotice from '../Header/ImportantNotice';
import { useNavigate } from "react-router-dom";
import { GetMarksixPageConfigs } from '../Common/ConfigHelper';
import { useWindowSize } from '../Common/CommonHooks';
import TopMenu from '../Menu/TopMenu';
import LeftMenu from '../Menu/LeftMenu';
import RedirectPage from '../Marksix/Common/RedirectPage';
import M6PageInfo from '../Marksix/Common/M6PageInfo'
import { MyFavouritesContext, getMyFavCookie } from '../Marksix/Common/MyFavourites';
import {RrawResultContext} from '../Marksix/Page/CheckDrawResult';
import { GlobalContext } from '../../StandardPage';
import { useQuery } from '@apollo/client';
import { getMarksixDrawQuery } from '../Marksix/Data/DataQuery';
import { getDefaultPageFromURL, isSnowballPage, isSnowballDraw } from '../Marksix/Common/Common';
import Loading from '../Common/ui-components/Loading';
import { errRedirectPage } from '../Common/utils';

export const MarksixContext = createContext();

const MarksixPage = (props) => {
    const globalContext = useContext(GlobalContext)
    const { t, i18n } = useTranslation();
    const curProduct = 'marksix';
    const navigate = useNavigate();
    const [content, setContent] = useState({ pageName: getDefaultPageFromURL(), config: null });
    const [myFavourites, setMyFavourites ] = useState([])
    const [checkRrawNumber, setCheckRrawNumber ] = useState({})
    const [curpageFavCheckArr, setCurpageFavCheckArr ] = useState([])
    const [favInitCheckState, setFavInitCheckState ] = useState('')
    const windowSize = useWindowSize();
    const isInitRef = useRef(true)

    const gqlVars = useQuery(getMarksixDrawQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: data=> {
            loadPageConfig();
            isInitRef.current = false
            globalContext.updateGlobalState({ product: curProduct, betType: content.pageName });
        },
        onError:err=>{
            errRedirectPage(err);
        }
    });

    function loadPageConfig() {
        if ( GetMarksixPageConfigs() ) {
            content.pageName = getDefaultPageFromURL();
            content.config = GetMarksixPageConfigs()[content.pageName];
            setContent({ ...content });
        }
        else {
            setTimeout(loadPageConfig, 500);
        }
    }

    useEffect(()=>{
        globalContext.updateGlobalState({ product: curProduct, betType: content.pageName });
    },[content.pageName])

    useEffect(() => {
        gqlVars.refetch();
    }, [window.location.pathname])

    useEffect(() => {
        loadPageConfig();
        setMyFavourites(getMyFavCookie())
    }, []);

    const updateMyFavourites = () => {
        setMyFavourites(getMyFavCookie())
    }

    const handleChangePage = (url, page, isAllup) => {
        if ( page.toUpperCase().indexOf("USERINFO") >=0 ) {
            content.pageName = "USERINFO";
        }
        else {
            content.pageName = page.toUpperCase();
        }
        content.config = GetMarksixPageConfigs()[content.pageName];
        setContent({ ...content });
        navigate(url);
    }

    if (content.config==null || (gqlVars.loading && isInitRef.current) ) {
        return <Loading />;
    } else {
        const lotteryDraws = gqlVars?.data?.lotteryDraws || [{},{},{}];
        let pageName = content.pageName;
        let pageConfig = content.config;
        let lastDraw = lotteryDraws[0];
        let nextDraw = lotteryDraws.length > 1 ? lotteryDraws[1] : {};
        let nextSbDraw = lotteryDraws.length > 2 ? lotteryDraws[2] : null;
        let isMergeDraw = isSnowballDraw(nextDraw);
        if ( isSnowballPage(pageName) && nextSbDraw!=null ) {
            nextDraw = nextSbDraw;
        }
        const ewinAtM6Obj={
            singleShowUnitBet:sessionStorage.getItem('Mk6TotalBetSpend') === '1',
            multipleShowUnitBet:sessionStorage.getItem('Mk6ByProdMulti') === '1',
            bankerShowUnitBet:sessionStorage.getItem('Mk6ByProdBanker') === '1',
            initDefaultUnitBet : sessionStorage.getItem('Mk6DefaultUnitBetAmount')?Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount')):10,
            initPartialUnitBet : sessionStorage.getItem('Mk6PartialUnitBetAmount')?Number(sessionStorage.getItem('Mk6PartialUnitBetAmount')):5
        }

        return (
            <MarksixContext.Provider value={{ gqlVars, pageConfig, pageName, lastDraw, nextDraw, nextSbDraw, isMergeDraw, ewinAtM6Obj, translation: t }}>
                <MyFavouritesContext.Provider value={{myFavourites, updateMyFavourites, curpageFavCheckArr, setCurpageFavCheckArr, favInitCheckState, setFavInitCheckState}}>
                <RrawResultContext.Provider value={{ checkRrawNumber, setCheckRrawNumber }}>
                    <div>
                        <Helmet>
                            <title>{t('LB_MARKSIX_TITLE')}</title>
                            <link rel="manifest" href={i18n.language=="en" ? "/manifest/manifest_m6E.json" : "/manifest/manifest_m6.json"} />
                            <meta name="description" content={t('LB_MARKSIX_DESC')} />
                        </Helmet>
                        <ImportantNotice product={curProduct} enable={pageConfig?.showImportantNotice} />
                        <div className="marksixMain">
                            <Banner Product={curProduct} enable={pageConfig?.showPromoBanner} />
                            {!windowSize.isMobileSize && <TopMenu Product={curProduct} onPageChange={handleChangePage} />}
                            <header className={content.pageName}>
                                {windowSize.isMobileSize && <M6PageInfo onPageChange={handleChangePage} />}
                            </header>
                            <div className='df M6Middel'>
                                {!windowSize.isMobileSize && <LeftMenu Product={curProduct} lang={i18n.language} onPageChange={handleChangePage} type={content.pageName} />}
                                <RedirectPage pageName={content.pageName} />
                            </div>
                        </div>
                    </div>
                </RrawResultContext.Provider>
                </MyFavouritesContext.Provider>
            </MarksixContext.Provider>
        )
    }
}
export default MarksixPage;