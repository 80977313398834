import React from 'react';
import { useTranslation } from 'react-i18next';

const RGPLinks = () => {
    const { t } = useTranslation();
    // FAQ   |   Contact Us 1 |  Media Communication System  |  Responsible Gaming Policy 4  |  Rules 3 |  Privacy 5  |  Disclaimer 6 |  Security Tips 7
    return (
        <div className="rgpLinks">
            <a rel="noopener noreferrer" href={t('URL_RGP_FAQ')} target="_blank">{t('LB_RGP_FAQ')}</a>
            <a rel="noopener noreferrer" href={t('URL_RGP_FEEDBACK')} target="_blank">{t('LB_RGP_FEEDBACK')}</a>
            <a rel="noopener noreferrer" href={t('URL_MCS')} target="_blank">{t('LB_MEDIA_COMMUNICATION_SYSTEM')}</a>
            <a rel="noopener noreferrer" href={t('URL_RGP_GAMBLING')} target="_blank">{t('LB_RGP_GAMBLING')}</a>
            <a rel="noopener noreferrer" href={t('URL_RGP_RULE')} target="_blank">{t('LB_RGP_RULE')}</a>
            <a rel="noopener noreferrer" href={t('URL_RGP_PRIVACY')} target="_blank">{t('LB_RGP_PRIVACY')}</a>
            <a rel="noopener noreferrer" href={t('URL_RGP_DISCLAIMER')} target="_blank">{t('LB_RGP_DISCLAIMER')}</a>
            <a rel="noopener noreferrer" href={t('URL_RGP_SECURITY')} target="_blank">{t('LB_RGP_SECURITY')}</a>
        </div>
    )
}
export default RGPLinks;