import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RacingContext } from '../../Home/RacingPage';
import { GlobalContext } from '../../../StandardPage';

import OddsTable from '../Common/RCOddsTable';
import OddsDropLegend from '../Common/OddsDropLegend';
import AddSlipBtn from '../Common/AddSlipBtn';
import Loading from '../../Common/ui-components/Loading';
import RefreshTime from '../../Common/ui-components/RefreshTime';

const JTCombo = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const globalContext = useContext(GlobalContext);
    const mtg = context.content.meeting;

    
    const Content = useCallback(({context, handleJTCCheckbox}) => {

       
        const RCColumns = {
            alw: true,
            raceNo: true,
            no: true,
            colour: false,
            horseName: true,
            rcNote: false,
            draw: true,
            wt: true,
            jocky: true,
            trainer: true,
            bodyWT: false,
            rtg: false,
            gear: false,
            last6: false,
            win: true,
            place: true,
            wp: true
        };
    
        return (
            <section className={context?.content?.page}>
                <RefreshTime product="racing" />
                <OddsTable allRaces="true" oddsCheckboxClickCallback={handleJTCCheckbox} columns={RCColumns} />
                <OddsDropLegend />
                <div className="jtcAddtoslip">
                    <div className="addSlip"><AddSlipBtn /></div>
                </div>
            </section>
        );
    },[]);
   

    if (mtg == null) {
        return <Loading/>
    } else if (globalContext.globalState.jtcPara.length==0) {
        return <div></div>
    } else {
        return (
            <Content context={context} {...props}/>
        );
    }
}
export default JTCombo;

