import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { NewWindow, NewWindowWithProps, getMenuParamByName, isLocalMeeting, postMsgToPeNote } from '../Common/home-common';
import { sortSeqIgnoreCase, isEarlySettlementEanbled } from '../Football/Common/Common';
import { GlobalContext } from '../../StandardPage';
import { isProductInfoItemEnable, MenuIcon, BottomMenuIcon, IconContent } from './MenuCommon';
import { useProductMenuQuery, useProductInfoBlockQuery, useMarksixFixturesQuery } from '../Home/Data/SitecoreDataHooks';
import { isLogonHigh } from '../BetSlip/LoginHooks';
import { useQuery } from '@apollo/client';
import { getRacingBlockQuery } from '../Racing/Data/DataQuery';
import { formatRacingDate, translateChangesItem,
    PenetroReading, HammerReading,
    JackPotInfo, EstDivInfo,
    hasJpt, hasEst, filterChangeItems, sortChanges } from '../Racing/Common/Common';
import { formatDateTime, scrollTo } from '../Common/GlobalFunc';
import { glassboxCustomEvent } from '../Common/utils/glassbox';
import { errRedirectPage } from '../Common/utils';
import ArrowIcon from '../Common/ui-components/ArrowIcon';
import { redirectJCRW } from '../Racing/Common/Common';

const MoreDialog = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);
    const fbMenuGqlVars = globalContext.fbMenuGqlVars;
    const [hrMenuLoading, hrMenuData] = useProductMenuQuery('racing');
    const [fbMenuLoading, fbMenuData] = useProductMenuQuery('football');
    const [m6MenuLoading, m6MenuData] = useProductMenuQuery('marksix');
    const [fbBlockLoading, fbBlockData ] = useProductInfoBlockQuery('football');
    const [m6BlockLoading, m6BlockData ] = useProductInfoBlockQuery('marksix');
    const { loading : fixtureLoading, getNext4Draw, getNextSnowballDraw } = useMarksixFixturesQuery();
    const [tournIdList, setTournIdList] = useState({ day: 60, list: "" });

    useEffect(()=> {
        if ( tournIdList!="" ) {
            globalContext.loadFootballMenuQuery(tournIdList.day, tournIdList.list.split(','));
        }
    }, [tournIdList.list])

    const { loading : hrBlockLoading, data: hrBlockData } = useQuery(getRacingBlockQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError:err=>{
            //errRedirectPage(err);
        }
    });

    const settings = {
        className: 'center',
        centerMode: true,
        centerPadding: "0px",
        infinite: false,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        speed: 500,
        autoplay: false,
        customPaging: i => {
            return <div className="dot"></div>
        }
    };

    const getMenu = (id, menu, close, enable, showNoSubMenu) => {
        if (!enable) {
            return null;
        }

        let predefinedColor = menu.predefinedColor?.targetItem?.optionValue?.value || "";
        let hlStyle = predefinedColor!="" ? { color: predefinedColor } : {};
        let cnt = menu.children.filter(x=> x.itemEnable.value==="1").length;
        if (cnt > 0 || showNoSubMenu) {
            return <Accordion key={menu.key}>
                <Accordion.Item eventKey={`variants-${menu.key}`} >
                    <Accordion.Header><div style={hlStyle}>{menu.itemName.value}<MenuIcon menu={menu} /></div></Accordion.Header>
                    <Accordion.Body>{cnt===0 ?
                    <div className="noinfo" onClick={()=>{ return false; }}>{t('LB_RC_JTC_NOINFO')}</div> :
                    menuItem(id, menu, close)}</Accordion.Body>
                </Accordion.Item>
            </Accordion >;
        }
        else {
            return <div className="no-child item" key={menu.key} onClick={() => handleOnClick(id, menu, close) }>
                <div style={hlStyle}>{menu.itemName.value}<MenuIcon menu={menu} /></div>
                {menu.itemLink.value.indexOf("//")>=0 && <div className="external-icon"></div>}
            </div>;
        }
    }

    const GeneralInfo = ({meetings}) => {
         return <Accordion>
            <Accordion.Item eventKey="GInfo">
                <Accordion.Header><div>{t('LB_RACE_INFO_BLOCK_GEN')}</div></Accordion.Header>
                <Accordion.Body>
                    {meetings?.length>0 ?
                        <Slider {...settings}>
                            {meetings.map((mtg, index) => {
                                let desc = [];
                                let firstRaceNo = Math.min(...mtg.races.map(x => x.no));
                                let firstRace = mtg.races.filter(x => x.no==firstRaceNo)[0];
                                let curRaceNo = mtg.currentNumberOfRace == 0 ? firstRaceNo : mtg.currentNumberOfRace;
                                let raceObj = mtg.races.filter(x => x.no == curRaceNo)[0];
                                if (raceObj && raceObj.raceTrack['description_' + i18n.language] != null && raceObj.raceTrack['description_' + i18n.language] != '')
                                    desc.push(raceObj.raceTrack['description_' + i18n.language]);
                                if (raceObj && raceObj.raceCourse['displayCode'] != null && raceObj.raceCourse['displayCode'] != '')
                                    desc.push(t('LB_RACE_RACE_COURSE').replace('{0}', raceObj.raceCourse['displayCode']));
                                if (raceObj && raceObj['go_' + i18n.language] != null && raceObj['go_' + i18n.language] != '')
                                    desc.push(raceObj['go_' + i18n.language]);
                                return <div>
                                    <div className="mtg-dt-venue">
                                        <div>{t('LB_RACE_INFO_BLOCK_MEETING')}<br/>{formatRacingDate(mtg.date)} ({t('LB_' + mtg.dateOfWeek + '_SHORT')})</div>
                                        <div className="mtg-btn">
                                            {isLocalMeeting(mtg.venueCode) ? t('LB_RACE_' + mtg.venueCode) : mtg.venueCode}
                                        </div>
                                    </div>
                                    <p>{t('LB_RACE_INFO_BLOCK_' + mtg.meetingType).replace('{0}', mtg.totalNumberOfRace).replace('{1}', t('LB_' + mtg.venueCode))}</p>
                                    <div>{t('LB_RACE_INFO_BLOCK_START')}</div>
                                    <div>{formatDateTime(firstRace?.postTime)?.[1]}</div>
                                    <div>{desc.join(', ')}</div>
                                    <PenetroReading reading={mtg.penetrometerReadings[0]} t={t} />
                                    <HammerReading reading={mtg.hammerReadings[0]} t={t} />
                                </div>
                            })}
                        </Slider>
                        : <div>{t('LB_RC_JTC_NOINFO')}</div>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    }

    const PoolInfo = ({meetings}) => {
        return <Accordion>
            <Accordion.Item eventKey="PInfo">
                <Accordion.Header><div>{t('LB_RACE_INFO_BLOCK_POOL_INFO')}</div></Accordion.Header>
                <Accordion.Body>
                    {meetings?.length>0 ?
                        <Slider {...settings}>
                            {meetings.map((mtg, index) => {
                                return <div>
                                    <div className="mtg-dt-venue">
                                        <div>{t('LB_RACE_INFO_BLOCK_MEETING')}<br/>{formatRacingDate(mtg.date)} ({t('LB_' + mtg.dateOfWeek + '_SHORT')})</div>
                                        <div className="mtg-btn">
                                            {isLocalMeeting(mtg.venueCode) ? t('LB_RACE_' + mtg.venueCode) : mtg.venueCode}
                                        </div>
                                    </div>
                                    <hr />
                                    <JackPotInfo betType="TRI" mtg={mtg} />
                                    <JackPotInfo betType="FF" mtg={mtg} />
                                    <EstDivInfo mtg={mtg} />
                                    {!hasJpt(mtg) && !hasEst(mtg) ? <div className="font-color-red" style={{ textAlign: "center"}}>{isLocalMeeting(mtg.venueCode) ? t('LB_RACE_INFO_SHORTLY') : t('LB_RC_JTC_NOINFO')}</div> : null}
                                </div>
                            })}
                        </Slider>
                        : <div>{t('LB_RC_JTC_NOINFO')}</div>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    }

    const Changes = ({ mtg }) => {
        const { t, i18n } = useTranslation();
        const filterChanges = mtg.changeHistories.filter(x=>filterChangeItems(x));
        if ( filterChanges.length==0 ) {
            return <div>{t('LB_RACE_CHANGES_NOCHG')}</div>
        }
        filterChanges.sort(sortChanges);
        return filterChanges.map((item) => {
            let msg = `${translateChangesItem(t, item, i18n.language, true)} (${moment(item.time).format("DD/MM HH:mm")})`;
            return <div onClick={() => { redirectJCRW(`${window.globalConfig.JCRW_URL}${t('URL_RC_JCRW_CHANGES')}`); }} className="blockChangesRow">{msg}</div>
        });
    }

    const ChangesInfo = ({meetings}) => {
        return <Accordion>
            <Accordion.Item eventKey="CInfo">
                <Accordion.Header><div>{t('LB_RACE_INFO_BLOCK_CHANGE')}</div></Accordion.Header>
                <Accordion.Body>
                    {meetings?.length>0 ?
                        <Slider {...settings}>
                            {meetings.map((mtg, index) => {
                                return <div>
                                    <div className="mtg-change">
                                        <div>
                                            <Changes mtg={mtg} />
                                        </div>
                                        <div>
                                            <div className="mtg-btn">
                                                {isLocalMeeting(mtg.venueCode) ? t('LB_RACE_' + mtg.venueCode) : mtg.venueCode}
                                            </div>
                                        </div>
                                    </div>
                                    {mtg.changeHistories.filter(x=>filterChangeItems(x)).length >= 3 &&
                                        <div className="blockButton">
                                            <div className="blockChangesMore btn" onClick={() => { redirectJCRW(window.globalConfig.JCRW_URL + t('URL_RC_JCRW_CHANGES')); }}>{t('LB_RACE_CHANGES_MORE')}</div>
                                        </div> }
                                </div>
                            })}
                        </Slider>
                        : <div>{t('LB_RC_JTC_NOINFO')}</div>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    }

    

    const handleOnClick = (id, menu, close, url) => {
        if (url) {
            let toUrl = `/${i18n.language}/${id}/${url}`;
            scrollTo(0, 0);
            enableScrollBar();
            close();
            navigate(toUrl);

        }
        else if( menu.itemRedirection.value===window.sitecoreVars.REDIRECT_JCBW_PAGE && menu.itemPage.value!=="" ) {
            let toUrl = menu.itemPage.value.startsWith('/') ? menu.itemPage.value : `/${i18n.language}/${id}/${menu.itemPage.value}`;
            scrollTo(0, 0);
            enableScrollBar();
            close();
            navigate(toUrl);
            const Int_Results = 'Int_Match Results'
            if([Int_Results].includes(menu?.key)){
                globalContext.setJumpFbHomePage(Math.random());
            }
        }
        else if ( menu.itemLink.value!=="" ) {
            if ( menu.redirectMethod.value===window.sitecoreVars.REDIRECT_POPUP) {
                let winProps = getMenuParamByName(menu);
                NewWindowWithProps(menu.itemLink.value, menu.key, winProps);
            }
            else if ( menu.redirectMethod.value===window.sitecoreVars.REDIRECT_REPLACETAB ) {
                // NewWindowWithProps(menu.itemLink.value, "_self");
                redirectJCRW(menu.itemLink.value);
            }
            else {
                window.open(menu.itemLink.value);
            }
        }

        if (menu.GlassboxTaggingEnabled?.value == '1') {
            const name = menu.GlassboxCustomEventName?.value || "";
            const value = menu.GlassboxCustomEventValue?.value || "";
            if (name != "" && value != "") {
                glassboxCustomEvent(name, value);
            }
        }

    }

    const menuItem = (id, menu, close) => {
        return menu.children.map((_cItem, _cIndex) => {
            let predefinedColor = _cItem.predefinedColor?.targetItem?.optionValue?.value || "";
            let showMenu = _cItem.key!=window.sitecoreVars.MENU_EARLY_SETTLEMENT || isEarlySettlementEanbled(globalContext);
            let hlStyle = predefinedColor!="" ? { color: predefinedColor } : {};
            if ( _cItem.itemEnable.value!=="1" || !showMenu ) {
                return null;
            }
            else if ( _cItem.key===window.sitecoreVars.MENU_AUTO_RACING_NOTES ) {
                return <div key={_cItem.key} className={`${_cIndex>0 ? "top-border" : ""}`} style={hlStyle} onClick={() => handleClickRacingNotes()}>
                    {_cItem.itemName.value }<MenuIcon menu={_cItem} />
                </div>
            }
            else if ( _cItem.key===window.sitecoreVars.MENU_AUTO_TOURN_RESULTS ) {
                if ( _cItem.frontEndIds.value!="" && tournIdList.list=="" ) {
                    let d = _cItem.noOfDays.value!="" ? parseInt(_cItem.noOfDays.value) : 60;
                    setTournIdList({day: d, list: _cItem.frontEndIds.value});
                }
                let menuList = [];
                fbMenuGqlVars?.data?.tournamentResult?.sort(sortSeqIgnoreCase);
                fbMenuGqlVars?.data?.tournamentResult?.forEach(item=> {
                    let tournUrl = 'tournres/' + item.id;
                    let cTemp = item["name_"+i18n.language];
                    menuList.push(<div key={tournUrl} className={`${_cIndex>0 ? "top-border" : ""}`} style={hlStyle} onClick={() => handleOnClick(id, "", close, tournUrl)}>
                        {cTemp}<MenuIcon menu={_cItem} />
                    </div>);
                });
                return menuList;
            }

            return <div key={_cItem.key} className={`${_cIndex>0 ? "top-border" : ""}`} style={hlStyle} onClick={() => handleOnClick(id, _cItem, close)}>
                {_cItem.itemName.value }<MenuIcon menu={_cItem} />
                <span style={{ paddingLeft: "4px" }}>
                    <IconContent iconContent={_cItem.iconContent} />
                </span>
            </div>

        });
    }

    const getDrawList = (id, menu, draws, close) => {
        let _url = "fixtures";
        return <Accordion key={menu.key}>
            <Accordion.Item eventKey={`variants-${menu.key}`} >
                <Accordion.Header><div>{menu.itemName.value}<MenuIcon menu={menu} /></div></Accordion.Header>
                <Accordion.Body>{draws.length === 0 ?
                    <div className="noinfo" onClick={()=>{ return false; }}>{t('LB_M6_INFO_BLOCK_NOINFO')}</div> :
                    draws.map((x, idx) => {
                        let dt = x;
                        return <div key={_url} className={idx>0 ? "top-border" : ""} onClick={() => handleOnClick(id, "", close, _url)}>
                            {dt.format("DD/MM/YYYY")} ({t('LB_' + dt.format("ddd").toUpperCase())})
                        </div>
                    })}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion >
    }

    const disableScrollBar = () => {
        document.body.style.overflow = "hidden";

        setTimeout(() => {
            document.getElementById("moreMenu" + props.product)?.scrollIntoView()
        }, 500);
    }
    const enableScrollBar = () => {
        document.body.removeAttribute('style');
    }

    const handleClickRacingNotes = () => {
        if ( !isLogonHigh() ) {
            alert(t('LB_RC_NOTES_MSG'));
        }
        else {
            let peNoteLang = i18n.language === 'en' ? 'english' : 'chinese';
		    let trCodeLang = i18n.language === 'en' ? 'E' : 'C';
            let pop = NewWindow(window.globalConfig.PENOTES_URL + window.globalConfig.PENOTE_ENDPOINT.menu.replace('{0}', peNoteLang).replace('{2}', trCodeLang), 'racingNotes', 780, 640, 1, 1);
            postMsgToPeNote(pop);
        }
    }

    const menuProps = {
        getDrawList, getMenu, getNext4Draw,  getNextSnowballDraw, GeneralInfo, PoolInfo, ChangesInfo, globalContext
    }

    return (
        <Popup
            onOpen={disableScrollBar}
            onClose={enableScrollBar}
            trigger={
                <a id={`bottom-menu-more`} role='button' className={`bottomMenuTab ${props.active} ${props.cashoutIP}`} onClick={props.handleClickBetslipBtn} >
                    <div className="bottom-menu-icon-container">
                        <div className="bottom-menu-icon"><BottomMenuIcon menu={props.iconMenu} /></div>
                        <div>{props.iconMenu.itemName.value}</div>
                    </div>
                </a>
            }
            modal
            nested
        >
            {close => (
                <div className="modal more">
                    <button className="close" onClick={close}>
                        {/* <li className="arrow arrow-left"></li> */}
                        <ArrowIcon className='hueRotate' direction='left'  size={40} />
                    </button>
                    <div className="header">
                        <div>{t('LB_BMENU_MORE')}</div>

                    </div>
                    <div className="content">
                        <Menu id="racing" name={t('LB_BMENU_RACING')} close={close} loading={hrMenuLoading || hrBlockLoading} data={hrMenuData} blockData={hrBlockData} {...menuProps}/>
                        <Menu id="football" name={t('LB_BMENU_FOOTBALL')} close={close} loading={fbMenuLoading || fbBlockLoading} data={fbMenuData} blockData={fbBlockData} {...menuProps}/>
                        <Menu id="marksix" name={t('LB_BMENU_MARKSIX')} close={close} loading={m6MenuLoading || m6BlockLoading || fixtureLoading} data={m6MenuData} blockData={m6BlockData} {...menuProps}/>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default MoreDialog;

const Menu = ({ id, name, close, loading, data, blockData, getDrawList, getMenu, getNext4Draw,  getNextSnowballDraw, GeneralInfo, PoolInfo, ChangesInfo, globalContext}) => {
    if ( loading ) {
        return null;
    }

    let raceBtnList = null;
    let blockList = [];

    if ( id==="racing" ) {
        const mtgs = blockData?.raceMeetings || [];
        blockList.push(<GeneralInfo meetings={mtgs} />);
        blockList.push(<PoolInfo meetings={mtgs} />);
        blockList.push(<ChangesInfo meetings={mtgs} />);
    }
    else {
        blockList = blockData.map((menu) => {
            if ( menu.key===window.sitecoreVars.M6_SBDRAW ) {
                return isProductInfoItemEnable(menu) ? getDrawList(id, menu, getNextSnowballDraw(), close) : null;
            }
            else if ( menu.key===window.sitecoreVars.M6_NEXTDRAW ) {
                return isProductInfoItemEnable(menu) ? getDrawList(id, menu, getNext4Draw(), close) : null;
            }
            return getMenu(id, menu, close, isProductInfoItemEnable(menu), true);
        });
    }

    let list = data.map((menu) => {
        let showMenu = menu.key!=window.sitecoreVars.MENU_EARLY_SETTLEMENT || isEarlySettlementEanbled(globalContext);
        return getMenu(id, menu, close, showMenu && menu.itemEnable.value==="1", false);
    });

    return <div id={`moreMenu${id}`} className={id}>
        <header>{name}</header>
        {blockList}
        {list}
    </div>
}