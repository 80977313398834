import React,{ useContext, useEffect, useRef, useState } from "react"

import { GlobalContext } from '../../../StandardPage';
import { MyBetSlipContext } from '../BetSlipHooks'
import { getBetSlipTopics } from './common'
import { parseMessage } from './parseMessage'
import useBSDataRefresh from "../useBSDataRefresh";
import { isLogonHigh } from "../LoginHooks";
import { consoleLog, formatDateObj } from '../../Common/GlobalFunc';
import moment from "moment";

const useBetSlipPush = ({isShowPreviewInfo,computePanelAllupFunc}) =>{
    const globalContext  = useContext(GlobalContext)
    const { isPushing, BSTmpMsgQueue, resubscribeBSTopic, pubCacheTopic } = globalContext.mqttClient
    const {
        betBaskets,
        setBetBaskets,
        currentBasket,
        setCurrentBasket,
        balance,
        setBalance,
        isSpeedbet,
        setIsSpeedbet,
        betBaskets_0,
        firstTimeFromJCRWRedirect
    } = useContext(MyBetSlipContext)
    const currentTopicsRef = useRef([])
    const pushTimer = useRef(null)
    const betBasketsRef = useRef(betBaskets)
    const { queryBetSlipData } = useBSDataRefresh({betBasketsRef,computePanelAllupFunc})
    const isPreviewRef = useRef(isShowPreviewInfo)
    isPreviewRef.current = isShowPreviewInfo
    const autoRefreshTimer = useRef(null)
    const autoRefreshTimeConuterRef = useRef(0)
    const enableOddsPushRef = useRef(globalContext.globalState.commonCtrl.enableOddsPushButton)
    enableOddsPushRef.current = globalContext.globalState.commonCtrl.enableOddsPushButton
    const lastPushTimeRef = useRef(null)

    useEffect(()=>{
        betBasketsRef.current = betBaskets ?  [...betBaskets] : []
        const curTopics = getBetSlipTopics(betBasketsRef)
        if(isPushing && window.globalConfig.BS_DATA_REFRESH){
            if(JSON.stringify(currentTopicsRef.current) != JSON.stringify(curTopics)){
                resubscribeBSTopic(curTopics)
            }
        }
        currentTopicsRef.current = curTopics
    },[betBaskets, isPushing])

    useEffect(()=>{
        if(isShowPreviewInfo || !window.globalConfig.BS_DATA_REFRESH){
            clearInterval(pushTimer.current)
            clearInterval(autoRefreshTimer.current)
        }
        else if(isPushing && enableOddsPushRef.current){
            const updateBSBasket = () => {
                const queue = [...BSTmpMsgQueue.current]
                BSTmpMsgQueue.current = []
                queue.forEach(x =>{
                    parseMessage(x.topic, x.message, betBasketsRef)
                })
                window.bsMatchinfoCache=[];
                setBetBaskets(baskets=> {
                    const newBaskets = baskets?.map((betLines, index) =>{
                        return betLines?.map((betLine)=>{
                            let newBetLine = betBasketsRef.current[index].find(item  => item.id == betLine.id)
                            if(newBetLine){
                                return newBetLine
                            }
                            return betLine
                        })
                    })
                    computePanelAllupFunc(newBaskets)
                    return newBaskets
                })
            }
            resubscribeBSTopic(currentTopicsRef.current)
            let cacheTopics = currentTopicsRef.current.map(item => {
                return {
                    recoveryTopic: item.replace(/\+/g,'*'),
                    requestAllIndicator: 0
                }
            })
            pubCacheTopic(cacheTopics)
            if(BSTmpMsgQueue.current.length > 0){
                updateBSBasket()
            }
            pushTimer.current = setInterval(()=>{
                if(isPreviewRef.current) return
                lastPushTimeRef.current = moment()
                updateBSBasket()
            },3000)
        }else{

            clearInterval(pushTimer.current)
            clearInterval(autoRefreshTimer.current)

            const autoRefreshInterval =window.fbConfig[isLogonHigh() ? 'autoRefreshIntervalInplay_login' : "autoRefreshIntervalInplay"] * 1000
            const maxCounter = window.fbConfig['autoRefreshIntervalNormal']
            autoRefreshTimer.current = setInterval(()=>{
                let now = moment()
                if(lastPushTimeRef.current && now.diff(lastPushTimeRef.current, 'second') < 30 ) return
                autoRefreshTimeConuterRef.current += (autoRefreshInterval/1000)
                let inplayOnly = autoRefreshTimeConuterRef.current < maxCounter
                queryBetSlipData({inplayOnly: false})
                let date = moment().format('hh:mm:ss')
                consoleLog('betslip autorefresh:' , date, 'inplayOnly:', false )
                if(maxCounter <= autoRefreshTimeConuterRef.current) autoRefreshTimeConuterRef.current = 0
            },autoRefreshInterval)
        }
        return () =>{
            clearInterval(pushTimer.current)
            clearInterval(autoRefreshTimer.current)
        }
    },[isPushing, isShowPreviewInfo, isLogonHigh()])

}

export default useBetSlipPush