/*NOTE: This code overrides the viewport settings, an improvement would beto take the original value and only add or change the user-scalable value*/
// optionally only activate for iOS (done because I havn't tested the effect under other OS/devices combinations such as Android)
var iOS = window.navigator?.userAgent && /iPad|iPhone|iPod/.test(window.navigator.userAgent);
if (iOS && window.screen.availWidth >=375 && sessionStorage.getItem('isDeskTopVersion') !== '1') {
    preventZoomOnFocus();
}
function preventZoomOnFocus() {
    document.documentElement.addEventListener("touchstart", onTouchStart);
    document.documentElement.addEventListener("focusin", onFocusIn);
}
// let dont_disable_for = ["checkbox", "radio", "file", "button", "image", "submit", "reset", "hidden"];
//let disable_for = ["text", "search", "password", "email", "tel", "url", "number", "date", "datetime-local", "month", "year", "color"];
function onTouchStart(evt) {
    // disable zoom
    setViewport("width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"); setTimeout(() => { setViewport("width=device-width, initial-scale=1.0, user-scalable=yes"); }, 1000);
}
// NOTE: for now assuming this focusIn is caused by user interaction
function onFocusIn(evt) {
    // reenable zoom
    setViewport("width=device-width, initial-scale=1.0, user-scalable=yes");
}
// add or update the <meta name="viewport"> element
function setViewport(newvalue) {
    let vpnode = document.documentElement.querySelector('head meta[name="viewport"]');
    if (vpnode) {
        vpnode.setAttribute("content", newvalue);
    } else {
        vpnode = document.createElement("meta");
        vpnode.setAttribute("name", "viewport");
        vpnode.setAttribute("content", newvalue);
        document.head.append(vpnode)
    }
}

function setInitialScale(){
    const metaNode = document.documentElement.querySelector('head meta[name="viewport"]')
    const metaContent = metaNode.getAttribute('content')
    const windowSize = window.screen.availWidth
    const scaleValue = windowSize/375 > 1 ? 1 : (windowSize/375).toFixed(2)
    const newContent = metaContent.split(',').map(item =>{
        if(item?.split?.('=')?.[0].includes('initial-scale')){
            return `initial-scale=${scaleValue}`
        }
        return item
    }).join(',')
    setViewport(`${newContent},target-densitydpi=device-dpi`)
}
sessionStorage.getItem('isDeskTopVersion') !== '1' && setInitialScale()


window.onload = () => {
    if(sessionStorage.getItem('isDeskTopVersion') === '1') {
        setViewport("user-scalable=yes");
    }

    if(navigator.userAgent.includes('XiaoMi/MiuiBrowser')) { // 處理小米瀏覽器bug SQ0248-3872
        // let startX = 0;
        // let startY = 0;
        // const ignore = ['.venue-list', '.RCMeetingSlider', '#dateListUi', '.matches'] // 需要忽略的各個列表。
        // const scrollBox = ignore.map(i => document.querySelector(i))
        // document.addEventListener("touchstart",function(e){
        //     startX = e.targetTouches[0].pageX;
        //     startY = e.targetTouches[0].pageY;
        // });
        // document.body.addEventListener('touchmove', function(e) {
        //     const moveX = e.targetTouches[0].pageX;
        //     const moveY = e.targetTouches[0].pageY;
        //     const findScrollBox =  scrollBox.find(i => isChildTarget(e.target, i))
        //     if (!findScrollBox ) {
        //         if(Math.abs(moveX-startX)>=Math.abs(moveY-startY)){
        //           e.preventDefault();
        //         }
        //     } else {
        //         const left = findScrollBox.scrollLeft;
        //         const cw = findScrollBox.clientWidth;
        //         const sw = findScrollBox.scrollWidth;
        //         if ((left === 0 && moveX > startX) || (left + cw === sw && moveX < startX)) {
        //             e.preventDefault();
        //         }
        //     }
        // }, { passive: false });

        // function isChildTarget(child, parent, justChild) {
        //     var parentNode;
        //     if (justChild) {
        //         parentNode = child.parentNode;
        //     } else {
        //         parentNode = child;
        //     }

        //     if (child && parent) {
        //         while (parentNode) {
        //             if (parent === parentNode) {
        //                 return true;
        //             }
        //             parentNode = parentNode.parentNode;
        //         }
        //     }
        //     return false;
        // }
    }
}
