import React, { useContext, useCallback, useState, useEffect, useRef, } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { GlobalContext } from '../../../StandardPage';
import { NewWindow } from '../../Common/home-common';
import { SingleMessage } from '../Common/FootballTitle';
import { toDateFormat, isMatchResultPage, getFootballHelpUrl } from './Common';
import {
    isMatchKickOff,
    MatchCountLabel,
    getNumberSuffix,
    RunningCorner,
    getFoPool,
    FlagTournIcon
} from './MatchBase';
import GeneralTournTable from './GeneralTournTable'
import TQLTable from './TQLTable'
import { getDayOfWeekShort } from '../../Common/home-common';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import MatchTableRow,{getAvailableLine} from './MatchTableRow';
import useGetMatches from '../Data/useGetMatches';


// import Pagination from '../Common/Pagination';

const HeaderOddsTitle = ({ betType, lblSuffix }) => {
    const { t } = useTranslation();

    let titleList = {
        HOME: false, HOME_TEAM: false, DRAW: false, AWAY: false, AWAY_TEAM: false,
        HIGH: false, LOW: false, NO_GOALS: false,
        ODD: false, EVEN: false,
        HH: false, HD: false, HA: false,
        DH: false, DD: false, DA: false,
        AH: false, AD: false, AA: false,
    }
    switch (betType?.toUpperCase()) {
        case 'HAD':
        case 'FHA':
        case 'HHA':
        case 'OFM':
        case 'INPLAY':
            titleList = { HOME: true, DRAW: true, AWAY: true }
            break;
        case 'HDC':
            titleList = { HOME: true, AWAY: true }
            break;
        case 'FTS':
            titleList = { HOME_TEAM: true, NO_GOALS: true, AWAY_TEAM: true }
            break;
        case 'HIL':
        case 'FHL':
        case 'CHL':
        case 'FCH':
            titleList = { HIGH: true, LOW: true }
            break;
        case 'OOE':
            titleList = { ODD: true, EVEN: true }
            break;
    }
    return <div className='odds'>
        <span>{t('LB_ODDS')}</span>
        <div>
            {titleList.HOME && <div className='H'>{t('LB_FB_HOME_' + lblSuffix)}</div>}
            {titleList.HOME_TEAM && <div className='H'>{t('LB_FB_HOME_TEAM_' + lblSuffix)}</div>}
            {titleList.DRAW && <div className='D'>{t('LB_FB_DRAW_' + lblSuffix)}</div>}
            {titleList.NO_GOALS && <div className='G'>{t('LB_FB_NO_GOALS_' + lblSuffix)}</div>}
            {titleList.AWAY && <div className='A'>{t('LB_FB_AWAY_' + lblSuffix)}</div>}
            {titleList.AWAY_TEAM && <div className='H'>{t('LB_FB_AWAY_TEAM_' + lblSuffix)}</div>}
            {titleList.HIGH && <div className='H'>{t('LB_FB_HIGH_' + lblSuffix)}</div>}
            {titleList.LOW && <div className='L'>{t('LB_FB_LOW_' + lblSuffix)}</div>}
            {titleList.ODD && <div className='O'>{t('LB_FB_ODD_' + lblSuffix)}</div>}
            {titleList.EVEN && <div className='E'>{t('LB_FB_EVEN_' + lblSuffix)}</div>}
            {titleList.HH && <div>
                <div className='HH'>{t('LB_FB_H_S')}-{t('LB_FB_H_S')}</div>
                <div className='HD'>{t('LB_FB_H_S')}-{t('LB_FB_D_S')}</div>
                <div className='HA'>{t('LB_FB_H_S')}-{t('LB_FB_A_S')}</div>
            </div>}
            {titleList.DH && <div>
                <div className='DH'>{t('LB_FB_D_S')}-{t('LB_FB_H_S')}</div>
                <div className='DD'>{t('LB_FB_D_S')}-{t('LB_FB_D_S')}</div>
                <div className='DA'>{t('LB_FB_D_S')}-{t('LB_FB_A_S')}</div>
            </div>}
            {titleList.AH && <div>
                <div className='AH'>{t('LB_FB_A_S')}-{t('LB_FB_H_S')}</div>
                <div className='AD'>{t('LB_FB_A_S')}-{t('LB_FB_D_S')}</div>
                <div className='AA'>{t('LB_FB_A_S')}-{t('LB_FB_A_S')}</div>
            </div>}
        </div>
    </div>
}

export const Header = (props) => {

    const context = useContext(FootballContext);
    const globalContext = useContext(GlobalContext);
    const availMatches = context.content.matchData.matches || [];
    const { t } = useTranslation();
    const MaxPaginationLimit = context.content.config.MaxPaginationLimit;
    const isInplayPage = context.content.config.InplayPages.includes(context.content.page);
    const lblSuffix = context.state.windowSize.isMobileSize ? 'S' : 'WIN';



    const totalMatches = availMatches.length;
    const disableInplayClock = context.content.pageConfig.disableInplayClock;
    const disableMatchHeader = context.content.pageConfig.disableMatchHeader;
    const hideOddsHeader = context.content.pageConfig.hideOddsHeader;
    const isMixAllUpPage = context.content.config.MixAllUpPages.includes(context.content.page);
    const h2hEnabled = globalContext.globalState.commonCtrl?.headToHeadIconEnabled;
    return <>
        <div className='pagination' id='fb_top_pagination'>
            <MatchCountLabel total={totalMatches} pageNum={props.pageNum} />
            <div>
                {/* <Pagination total={Math.ceil(totalMatches / MaxPaginationLimit)} onChange={(num) => props.handlePageNumChange(num)} pageNum={props.pageNum} /> */}
                <RefreshTime product="football" />
            </div>
        </div>
        {!disableMatchHeader && <div className='header'>
            {isMixAllUpPage && <div>&nbsp;</div>}
            <div className='date'><span>{t('LB_FB_EXPT_STOP_TIME_L1')}</span><br /><span>{t('LB_FB_EXPT_STOP_TIME_L2')}</span></div>
            <div className='fb-id'>{t('LB_FB_EVENT_ID')}</div>
            <div className='tourn'>
                <FlagTournIcon />
            </div>
            <div className='team'><span>{t('LB_FB_HOME_TEAM')}</span><span>{t('LB_FB_AWAY_TEAM')}</span></div>
            {isInplayPage && <div className='live-info' dangerouslySetInnerHTML={{ __html: t('LB_FB_LIVE_INFO')}}></div>}
            {isMixAllUpPage && <div className={`oddsLine ${context.content?.page}`}></div>}
            {!disableInplayClock && <div className="inplay"><span>{t('LB_FB_IN_PLAY_BETTING_1')}</span><span>{t('LB_FB_IN_PLAY_BETTING_2')}</span></div>}
            {!isMixAllUpPage &&
            <>
                <span className='line'>{t('LB_FB_LINE')}</span>
                {!hideOddsHeader ? <HeaderOddsTitle betType={props.betType} lblSuffix={lblSuffix} /> : <div className={`oddsLine ${context.content?.page}`}></div>}
                {h2hEnabled ? <div className='h2h'>{t('LB_FB_H2H')}</div> : <div style={{width:'16px'}}></div>}
            </>}
        </div>}
    </>
}

export const Coupon = ({ data, title, handleOnClick, isChecked, showHelpBtn, betType, etBetType, handleOnClickLineToggle, isShowLineToggle, isExpandLine, isCloseLine }) => {

    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const lblSuffix = context.state.windowSize.isMobileSize ? 'S' : 'WIN';
    const messgeSuffix = context.state.windowSize.isMobileSize ? ' - Mobile' : ' - Desktop';
    const isResultPage = isMatchResultPage(context.content.page);
    const messageObj = context.betTypeMessages?.find(x => x.enable.value==="1" && x.key===betType+messgeSuffix);

    const HelpBtn = () => {
        const onClick = (e) => {
            e.stopPropagation();
            NewWindow(getFootballHelpUrl(betType, i18n.language), 'specialSite', 800, 600, 1, 1)
        }
        return <div className="HelpBtn" onClick={onClick} />
    }

    const CouponOddsTitle = () => {
        let titleList = {
            HOME: false, HOME_TEAM: false, DRAW: false, AWAY: false, AWAY_TEAM: false, HIGH: false, LOW: false,
            LINE: false, NO_GOALS: false, ODD: false, EVEN: false
        }
        switch (betType.toUpperCase()) {
            case 'HAD':
            case 'FHA':
            case 'HHA':
            case 'OFM':
                titleList = { HOME: true, DRAW: true, AWAY: true }
                break;
            case 'HDC':
                titleList = { HOME: true, AWAY: true }
                break;
            case 'FTS':
                titleList = { HOME_TEAM: true, NO_GOALS: true, AWAY_TEAM: true }
                break;
            case 'HIL':
            case 'FHL':
            case 'CHL':
            case 'FCH':
                titleList = { HIGH: true, LOW: true, LINE: true }
                break;
            case 'OOE':
                titleList = { ODD: true, EVEN: true }
                break;
            case 'HFT':
                titleList = {
                    HH: true, HD: true, HA: true,
                    DH: true, DD: true, DA: true,
                    AH: true, AD: true, AA: true,
                }
                break;
        }
        return <div className='odds'>
            {titleList.HOME && <div className='H'>{t('LB_FB_HOME_' + lblSuffix)}</div>}
            {titleList.HOME_TEAM && <div className='H'>{t('LB_FB_HOME_TEAM_' + lblSuffix)}</div>}
            {titleList.DRAW && <div className='D'>{t('LB_FB_DRAW_' + lblSuffix)}</div>}
            {titleList.NO_GOALS && <div className='G'>{t('LB_FB_NO_GOALS_' + lblSuffix)}</div>}
            {titleList.AWAY && <div className='A'>{t('LB_FB_AWAY_' + lblSuffix)}</div>}
            {titleList.AWAY_TEAM && <div className='A'>{t('LB_FB_AWAY_TEAM_' + lblSuffix)}</div>}
            {titleList.LINE && <div className='L'>{t('LB_FB_LINE')}</div>}
            {titleList.HIGH && <div className='H'>{t('LB_FB_HIGH_' + lblSuffix)}</div>}
            {titleList.LOW && <div className='L'>{t('LB_FB_LOW_' + lblSuffix)}</div>}
            {titleList.ODD && <div className='O'>{t('LB_FB_ODD_' + lblSuffix)}</div>}
            {titleList.EVEN && <div className='E'>{t('LB_FB_EVEN_' + lblSuffix)}</div>}
        </div>
    }

    let isAllOddsPage = context.content.config.AllOddsPages.includes(context.content.page);
    let isMixAllUpDetailPage = context.content.config.MixAllUpDetailPages.includes(context.content.page);

    let isNTSPool = betType === 'ENT' || betType === 'NTS';
    let foPool = getFoPool(data, betType, etBetType);
    let goalNum = foPool?.instNo;
    let isStartSell = foPool?.status==="SELLINGSTARTED";
    let isKickOff = isMatchKickOff(data.status);
    if(context.content.betType==='INPLAY_ALL' && !isKickOff && data.status==='PREEVENT'){
        isKickOff=true;
    }
    let isShowSupended = !isResultPage && isKickOff && !isStartSell &&  (isAllOddsPage || isMixAllUpDetailPage); // PRJ1027-8603
    let isShowCornerResult = !isResultPage && (['CHL','ECH', 'FCH'].includes(betType)) && (isAllOddsPage || isMixAllUpDetailPage);

    let showMessage = !["HDC", "HHA", "TTG"].includes(betType) || foPool?.inplay;

    return <>
        {
            !context.state.windowSize.isMobileSize ? <><div onClick={handleOnClick} className={`coupon coupon${betType} ${!isChecked? 'couponExpand' : 'couponCollaspse' }`} id={`coupon${betType}${isCloseLine ? '_C' : data?.kickOffTime?.split('T')[0]?.split('-')?.join('')}`}>
                <div className='left'>

                    <div className='couponBtn'>
                        <ArrowIcon  className='hueRotate' direction='down' change={isChecked} changeDirection='up' size={20} />
                    </div>

                    <div className='desc'>
                        {title}

                    </div>

                    {!isResultPage && isNTSPool && data != null && <div className='goal-number'>
                        (
                        {
                            i18n.language === 'en' ? <>
                                {goalNum}<span className='suffix'>{getNumberSuffix(goalNum)}</span> {t('LB_FB_GOAL').replace('{0}', goalNum)}
                            </> : <>
                                {t('LB_FB_N_GOAL').replace('{0}', goalNum)}
                            </>
                        }
                        )
                    </div>}

                    {showHelpBtn && <HelpBtn />}

                    {isShowCornerResult && <RunningCorner match={data} foPool={foPool} />}

                    <div onClick={handleOnClick} className='supplement'>
                        {!isResultPage && (isAllOddsPage || isMixAllUpDetailPage) && <SingleMessage betType={betType} />}
                    </div>



                </div>

                <div className='right' onClick={(e) => { handleOnClickLineToggle(e,data.matchDate) }}>
                    {isShowLineToggle && <>
                        <span>{isExpandLine ? t('LB_FB_COLLAPSE_ALL') : t('LB_FB_EXPAND_ALL')}</span>
                        <div className="couponBtn">
                            <ArrowIcon id={`expandAll_${betType}_${data?.kickOffTime?.split('T')[0]?.split('-')?.join('')}`}  className='hueRotate' direction='down' change={isExpandLine} changeDirection='up' size={20} />
                        </div>
                    </>}
                </div>
            </div>
            {(isAllOddsPage || isMixAllUpDetailPage) && !isResultPage && messageObj && showMessage && isChecked && <div className="crsRemarks" dangerouslySetInnerHTML={{ __html: messageObj.message.value }}></div> }
            {isShowSupended && isChecked && <div className='lb-suspended'>{t('LB_FB_SUSPENDED')}</div>}
            </>
            : <><div className={`coupon-m coupon${betType}`} id={`coupon${betType}${isCloseLine ? '_C' : data?.kickOffTime?.split('T')[0]?.split('-')?.join('')}`}>
                <div className='desc'>
                    <span  className='desc-title'>{title}</span>
                    {isShowCornerResult && <RunningCorner match={data} foPool={foPool} />}
                </div>


                {!isResultPage && (isAllOddsPage || isMixAllUpDetailPage) && <SingleMessage betType={betType} />}
                {
                    (!isAllOddsPage && !isMixAllUpDetailPage) && <>
                        <CouponOddsTitle />
                        <div className='h2h' />
                    </>
                }

                {!isResultPage && isNTSPool && data != null && <div className='goal-number'>
                    (
                    {
                        context.state.lang === 'en' ? <>
                            {goalNum}<span className='suffix'>{getNumberSuffix(goalNum)}</span> {t('LB_FB_GOAL').replace('{0}', goalNum)}
                        </> : <>
                            {t('LB_FB_N_GOAL').replace('{0}', goalNum)}
                        </>
                    }
                    )
                </div>}
            </div>
            {(isAllOddsPage || isMixAllUpDetailPage)  && !isResultPage && messageObj && showMessage && <div className="crsRemarks 112" dangerouslySetInnerHTML={{ __html: messageObj.message.value }}></div> }
            {isShowSupended && <div className='lb-suspended lb-suspended-m'>{t('LB_FB_SUSPENDED')}</div>}
            </>
        }

    </>
}



const Table = ({ pageNum, betType, etBetType, singleMatch, showInplayLabel, isCloseLine }) => {

    const context = useContext(FootballContext);
    const availMatches = context.content.matchData.matches;
    const { t, i18n } = useTranslation();
    const MaxPaginationLimit = context.content.config.MaxPaginationLimit;
    const tableSetProps = {
        betType, etBetType, context, isCloseLine
    }
    // const content = () => {

    //     if (singleMatch != null) {
    //         let coponTitle = t('LB_FB_TITLE_' + betType);
    //         if (showInplayLabel) {
    //             coponTitle += '- ' + t('LB_FB_RESULT_LAST_ODDS_INPLAY');
    //         }
    //         let rows = [];
    //         rows.push({ data: singleMatch, betType: betType, etBetType: etBetType, lang: i18n.language });
    //         return <TableSet coponTitle={coponTitle} rows={rows} {...tableSetProps} key={coponTitle}/>
    //     }

    //     return getContentByDate();
    // }



    let coponTitle = t('LB_FB_TITLE_' + betType);
    let rows = [];
    if (singleMatch != null) {
        if (showInplayLabel) {
            coponTitle += '- ' + t('LB_FB_RESULT_LAST_ODDS_INPLAY');
        }
        rows.push({ data: singleMatch, betType: betType, etBetType: etBetType, lang: i18n.language });
    }

    const ContentByDateProps = {availMatches, pageNum, MaxPaginationLimit, context, betType, etBetType, tableSetProps}

    return (
        <>
            {singleMatch != null ?
            <TableSet coponTitle={coponTitle} rows={rows} {...tableSetProps} key={coponTitle}/>
            :
            <ContentByDate {...ContentByDateProps}/>
        }
        </>
    )

}

const ContentByDate = ({availMatches, pageNum, MaxPaginationLimit, context, betType, etBetType, tableSetProps}) => {

    const { t, i18n } = useTranslation();
    let coponTitle = '';
    let beforeDate = '';
    let tableSet = [];
    let matches = [];

    matches = availMatches?.slice(0,(pageNum * MaxPaginationLimit)) || [];
    // matches = availMatches?.filter((item, index) => index >= (pageNum - 1) * MaxPaginationLimit && index <= (pageNum * MaxPaginationLimit - 1)) || [];

    matches.map((item, index) => {

        let _matchDate = item.matchDate.substring(0, 10);
        if (_matchDate !== beforeDate) {
            beforeDate = _matchDate;

            let matchDate = item.matchDate.substring(0, 10);
            let matchDay = getDayOfWeekShort(matchDate, t);

            switch (context.state.page.toUpperCase()) {
                case 'ALLODDS':
                case 'INPLAY_ALL':
                case 'MIXALLUP_DETAIL':
                case 'RESULTS':
                    coponTitle = t('LB_FB_TITLE_' + betType.toUpperCase());
                    break;
                default:
                    coponTitle = toDateFormat(matchDate, true, false) + " (" + matchDay + ") " + t('LB_FB_MATCH');
                    break;
            }

            tableSet.push({ coponTitle: coponTitle, rows: [] })

        }

        tableSet[tableSet.length - 1].rows.push({ data: item, betType: betType, etBetType: etBetType, lang: i18n.language });
    })

    return tableSet.map((item, index) => {
        // item.rows = <div className='match-row-container'>{item.rows}</div>
        return <TableSet coponTitle={item.coponTitle} rows={item.rows} key={item.coponTitle + index} {...tableSetProps}/>
    })
}

const TableSet = ({ coponTitle, rows,  betType, etBetType, context, isCloseLine}) => {

    const [expand, setExpand] = useState(true);
    const [expandLine, setExpandLine] = useState(false);
    const [curRows, setCurRows] = useState([])

    const [lineStateList, setLineStateList] = useState([]);

    const getLineStateList = () => {

        let _rowStateLine = [];
        rows.map((row, index) => {
            let foPool = getFoPool(row.data, betType, etBetType);
            if (foPool == null) {
                return;
            }
            let poolId = foPool.id;

            if ( context.content.config.SGAPools.includes(betType) ) {
                poolId = "SGA";
            }

            let lines = isCloseLine ? foPool.closedLines : foPool.lines;
            lines = lines?.filter(x=> x.status!="SUSPENDED");
            _rowStateLine.push({
                id: poolId,
                date: row.data.matchDate,
                // expand: expandLine,
                expand: lineStateList?.find(i => { //PRJ1027-7247
                    return i.id === poolId
                })?.expand || false,
                lines
            });
        });
        return _rowStateLine
    }



    useEffect(()=>{
        if(rows){
            setLineStateList(getLineStateList())
            setCurRows(oldRows =>{
                if(oldRows?.length < rows.length){
                    setExpand(true)
                }
                return rows
            })
        }
    },[rows])

    const toggle = () => {
        setExpand(!expand)
    }
    const handleOnClickLineToggle = (ev,date) => {
        let newState = [...lineStateList];
        const newExpandLineState= !expandLine;
        newState.forEach(item => {
            if (item.date === date) {
                item.expand = newExpandLineState;
            }
        })
        !expand && setExpand(!expand)
        setExpandLine(newExpandLineState)
        setLineStateList(newState)
        ev.stopPropagation();
    }
    const handleSetLineState = (date, poolId) => {

        let newSate = [...lineStateList]
        newSate.forEach(item => {
            if (item.id === poolId) {
                item.expand = !item.expand;
            }
        })

        let isExpandAll = newSate.filter(x => x.date === date && x.expand).length == newSate.filter(x => x.date === date && x.lines.length > 1).length;

        setLineStateList(newSate)
        setExpandLine(isExpandAll);
    }

    const Rows = useCallback(({rows,betType,  etBetType , lineStateList , handleSetLineState , isCloseLine, context}) => {

        return rows.map((row, index) => {
            let foPool  = getFoPool(row.data, row.betType, row.etBetType);

            // if(['MIXALLUP', 'INPLAY', 'OFM'].includes(context.content.page)){
            //     foPool = true
            // }
            return <MatchTableRow data={row.data} betType={row.betType} etBetType={row.etBetType} index={index} key={index}
                expandState={lineStateList} handleSetLineState={handleSetLineState}
                isCloseLine={isCloseLine} />
        });
    },[])

    let isAllupPage = context.content.config.AllOddsPages.includes(context.state.page) || context.state.page.toUpperCase() == 'RESULTS';
    let isMixAllUpDetailPage = context.content.config.MixAllUpDetailPages.includes(context.content.page);
    let isOFMPage = context.state.page.toUpperCase() == 'OFM';
    const isResultPage = isMatchResultPage(context.content.page);
    let showLineToggle = false;
    let hideCoupon = context.content.config.SingleMatchPages.includes(context.state.page)
        && !context.content.config.AllOddsPages.includes(context.state.page);

    rows.map((v, i) => {
        let foPool = getFoPool(v.data, betType, etBetType);
        if (!showLineToggle && foPool != null)
            showLineToggle = isCloseLine ? getAvailableLine(foPool.closedLines, isResultPage).length > 1 : getAvailableLine(foPool.lines, isResultPage).length > 1;
    })

    const rowProps = {
        rows,betType,  etBetType , lineStateList , handleSetLineState , isCloseLine, context
    }

    return (<>
        {rows.length > 0 && !hideCoupon && <Coupon data={rows[0].data} title={coponTitle} handleOnClick={toggle} isChecked={expand} showHelpBtn={isAllupPage || isMixAllUpDetailPage}
            betType={betType} etBetType={etBetType} handleOnClickLineToggle={handleOnClickLineToggle} isShowLineToggle={showLineToggle && (!isAllupPage && !isMixAllUpDetailPage)}
            isExpandLine={expandLine} isCloseLine={isCloseLine} />}
        {isOFMPage ? <>{expand &&<div className='match-row-container'><Rows  {...rowProps}/></div>}</> : <>{expand && <div className='match-row-container'><Rows {...rowProps}/></div>}</>}
    </>)
}

const Content = (props) => {
    const {context, t, handlePageNumChange } = props
    // let selection = context.content.selection;
    let availMatches = context.content?.matchData?.matches
    let noPool = availMatches?.length == 0;

    return <>
        {
            noPool ? <div className='noInfoTip'>{t('LB_FB_PLS_REFRESH')}</div> : <>
                {<Header pageNum={context.content.pageNum} handlePageNumChange={(i) => handlePageNumChange(i)} betType={context.content.betType} />}
                <Table pageNum={context.content.pageNum} betType={props.betType} etBetType={props.etBetType} matchId={props.matchId}/>
            </>
        }
    </>
}

export const SingleMatchTable = (props) => {
    const { t } = useTranslation();
    const Content = useCallback(({betType, match, isCloseLine, foPool, etBetType, showInplayLabel, t}) => {
        let allTable = null;

        switch (betType) {
            case 'CHP':
                allTable = <GeneralTournTable betType={'CHP'} isMatch={true} match={match} tourn={match.tournament} />
                break;
            case 'TQL':
                allTable = <TQLTable betType={'TQL'} isMatch={true} match={match} tourn={match.tournament} isCloseLine={isCloseLine} />
                break;
            default:
                const lines = isCloseLine ? foPool?.closedLines : foPool?.lines;
                if ( lines?.length > 0 ) {
                    allTable = <Table pageNum={1} betType={betType} etBetType={etBetType} singleMatch={match} showInplayLabel={showInplayLabel} isCloseLine={isCloseLine} />
                }
        }
        return <>
            {
                foPool==null ? <div>{t('LB_FB_NOT_YET_DEFINED')}</div> : <>
                    {allTable}
                </>
            }
        </>
    },[])


    return <>
        <Content {...props} t={t}/>
    </>

}



const MatchTable = (props) => {
    const context = useContext(FootballContext);
    const { loadMatchQuery } = useGetMatches(context.contentRef, context.setContent)
    const availMatches = context.content.matchData.matches;
    const { t } = useTranslation();
    const MaxPaginationLimit = context.content.config.MaxPaginationLimit;
    const contentRef = context.contentRef;
    const [loadMore, setLoadMore] = useState(false)
    const loadMoreRef = useRef(false)
    loadMoreRef.current = loadMore
    const moreLoadingRef = useRef()
    const isMixAllUpPage = context.content.config.MixAllUpPages.includes(context.content.page);
    moreLoadingRef.current = {
        moreLoading: context.loadMoreLoading,
        setMoreLoading: context.state.setLoadMoreLoading
    }
    // const [moreLoading, setMoreLoading] = useState(false)

    useEffect(() => {
        if(contentRef.current.selection.type !== 'ALL' && !(contentRef.current.selection.type === 'SEARCH' && contentRef.current.selection.value.length == 0)){
            setLoadMore(false)
        }
    }, [context.content.matchData.matches.length, context.content.selection.type])

    useEffect(() => {
        if((contentRef.current.selection.type === 'ALL' || (contentRef.current.selection.type === 'SEARCH' && contentRef.current.selection.value.length == 0)) && context.content.listData.matches.length > 60 &&  contentRef.current.matchData.matches.length === 60){
            setLoadMore(false)
        }
    }, [context.content.listData.matches.length, context.content.selection.type])

    useEffect(()=>{
        window.addEventListener('scroll',resizePageNum)
        document.querySelector('.StandardPage-scrollbars>div:first-child')?.addEventListener('scroll',resizePageNum)
        return ()=> {
            window.removeEventListener('scroll',resizePageNum)
            document.querySelector('.StandardPage-scrollbars>div:first-child')?.removeEventListener('scroll',resizePageNum)
        }
    },[])

    const handlePageNumChange = async (val) => {
        context.state.updateContent("pageNum", val);
        if(contentRef.current.selection.type === 'ALL' ||  (contentRef.current.selection.type === 'SEARCH' && contentRef.current.selection.value.length == 0)){
            const startIndex = 1 + (val - 1 ) * MaxPaginationLimit
            const endIndex = val  * MaxPaginationLimit
            if(!isMixAllUpPage ){
                await loadMatchQuery({ page: contentRef.current.page, startIndex, endIndex });
            }
            setTimeout(()=>{
                moreLoadingRef.current.setMoreLoading(false)
                setLoadMore(true)
            }, 200);
        }else{
            setTimeout(()=>{
                moreLoadingRef.current.setMoreLoading(false)
                setLoadMore(true)
            }, 200)
        }
    }

    const handleClickLoadMore = () =>{
            moreLoadingRef.current.setMoreLoading(true)
            handlePageNumChange(2)
    }

    const resizePageNum = (e) =>{
        let triggerPoint = e.target === document ? 800 : 300
        let scrollTop = e.target.scrollTop || document.documentElement.scrollTop
        let scrollHeight = document.querySelector('.StandardPage')?.clientHeight
        if(!scrollHeight) return
        let windowHeight = window.innerHeight
        const isAllMatches = contentRef.current.selection.type === 'ALL' || (contentRef.current.selection.type === 'SEARCH' && contentRef.current.selection.value.length == 0)
        if((scrollTop + windowHeight ) >= ( scrollHeight - triggerPoint)  && loadMoreRef.current && !moreLoadingRef.current.moreLoading ){
            if((!isAllMatches  && MaxPaginationLimit * contentRef.current.pageNum >= contentRef.current.matchData?.matches.length) ||(isAllMatches && MaxPaginationLimit * contentRef.current.pageNum >=  contentRef.current.listData?.matches?.length)) return

            moreLoadingRef.current.setMoreLoading(true)
            handlePageNumChange(contentRef.current.pageNum + 1)
        }
    }


    const totalMatches = availMatches?.length;
    const {isMobileSize}=context.state.windowSize;
    const isAllMatches = contentRef.current.selection.type === 'ALL' || (contentRef.current.selection.type === 'SEARCH' && contentRef.current.selection.value.length == 0)
    const showMoreButtton = (context.content.matchData?.matches?.length > MaxPaginationLimit) || (isAllMatches && context.content.listData?.matches?.length > MaxPaginationLimit)

    const contentProps = {
        context,
        ...props,
        t,
        handlePageNumChange
    }

    return <>
        <Content  {...contentProps}/>
        {
           ((!loadMore &&  showMoreButtton ) || moreLoadingRef.current.moreLoading) && <div className='FBMatchListLoadMoreContainer'>
               {moreLoadingRef.current.moreLoading ? <Loading className='fbMatchloadMoreLoading'/>
                :
                contentRef.current.pageNum === 1 && <div  className='FBMatchListLoadMoreButton' onClick={handleClickLoadMore}>{t('LB_FB_SHOW_MORE')}</div>
                }
           </div>
        }
        {
            <div className='pagination' id='fb_bottom_pagination'>
                <MatchCountLabel total={totalMatches} pageNum={context.content.pageNum} />
            </div>
        }
    </>

};

export default MatchTable;

