import React,{ useState, useEffect, useRef, useContext } from 'react'
import {Cookies} from '../../Common/CookieHelper'
import { useWindowSize } from '../../Common/CommonHooks';
import useGetMatches from '../Data/useGetMatches';
import { useTranslation } from 'react-i18next';

export const useFBSearch = (contentRef, setContent, content)=> {
    const { loadMatchQuery } = useGetMatches(contentRef, setContent);
    const [activeSearch, setActiveSearch] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [allTeamList, setAllTeamList] = useState([])
    const [showSearchHistory, setShowSearchHistory] = useState(false)
    const [isClearSearchResultBtn, setIsClearSearchResultBtn] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)
    const [searchResults, setSearchResult] = useState(null)
    const [searchItem, setSearchItem] = useState(null)
    const [matches, setMatches] = useState([])
    const { isMobileSize } = useWindowSize();
    const [showMobileInputBox, setShowMobileInputBox] = useState(false)
    const [isNewPage, setIsNewPage] = useState(true)
    const [isUpdate, setIsUpdate] = useState(false)
    const {t} = useTranslation()

    useEffect(()=>{
        document.body.addEventListener('click', onHideDropDown)
        const fbSearchHistory = getFBSearchHistory()
        setSearchResult(fbSearchHistory)
        if(fbSearchHistory.length > 0 ){
            setShowSearchHistory(true)
        }
        return ()=>{
            document.body.removeEventListener('click', onHideDropDown)
        }
    },[])

    useEffect(() =>{
        contentRef.current.selection.searchParams = searchItem
    },[searchItem])

    useEffect(() => {
        if(contentRef.current.selection.type != 'SEARCH'){
            // if(searchText === searchItem){
            setActiveSearch(false)
            // setSearchItem(null)
            setIsClearSearchResultBtn(false)
            const fbSearchHistory = getFBSearchHistory()
            setSearchResult(fbSearchHistory)
            if(fbSearchHistory.length > 0 ){
                setShowSearchHistory(true)
            }
        }else{
            setShowMobileInputBox(true)
        }
    }, [content.selection.type]);


    useEffect(()=>{
        if(contentRef.current.selection.type === 'SEARCH'){
            if(searchText.trim()){
                setShowSearchHistory(false)
                const fbSearchHistory = getFBSearchHistory().filter(team => {
                    if(searchText.replace('(', '').replace(')',"").trim().length > 0){
                        return team.label.toLowerCase().includes(searchText.toLowerCase().trim())
                    }
                })
                const filterTeam = allTeamList.filter(team => {
                    if(searchText.replace('(', '').replace(')',"").trim().length > 0){
                        return team.label.toLowerCase().includes(searchText.toLowerCase().trim()) && !fbSearchHistory.find(i => i.label === team.label)
                    }
                })
                const newTeamList = [...fbSearchHistory, ...filterTeam]
                setSearchResult(newTeamList)
            }else{
                const fbSearchHistory = getFBSearchHistory()
                setSearchResult(fbSearchHistory)
                if(fbSearchHistory.length > 0 ){
                    setShowSearchHistory(true)
                }
            }
        }
    },[searchText, allTeamList])

    useEffect(()=>{
        const matches = [...contentRef.current.rawMatchList] || []
        setMatches((oldMatches)=> {
            if(matches && (oldMatches?.length !== matches.length)){
                setAllTeamList(filterMatches(matches))
                    setIsUpdate(true)
                return matches
            }else{
                return oldMatches
            }
        })
    },[content.rawMatchList.length])

    useEffect(()=>{
        const isAllUpDtls = contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)
        if(allTeamList && allTeamList.length >0  && isUpdate && contentRef.current.selection.type === 'SEARCH' && !isAllUpDtls){
            if(isNewPage){
                setIsNewPage(false)
            }else{
                if(searchItem?.label && searchItem?.label === searchText){
                    contentRef.current.isLoading = true
                    handleClickTeamName(searchItem)
                }
            }
            setIsUpdate(false)
        }
    },[allTeamList, searchItem, matches, isUpdate,isNewPage, handleClickTeamName,searchText])

    const clearSearchText = () =>{

        setSearchText('')
        setSearchItem(null)
        setIsClearSearchResultBtn(false)
    }

    const onInputSearchText = (e) => {
        let value = e.target.value
        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
            if (e.nativeEvent.data != null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute('data-init', 0);
        }
        setSearchText(value)
        setInputFocus(true)
    }

    const onKeyDownEnterKey = (e) =>{
        if((e.keyCode || e.which) === 13){
            e.target.blur()
            setShowMobileInputBox(false)
            handleClickTeamName({
                label: searchText,
                type: 'ALL'
            })
        }
    }

    const onBlurSearchInput = (e) =>{
        setTimeout(() => {
            setInputFocus(false)
        }, 200);
    }

    const onHideDropDown = (e) => {
        const searchNodeM = document.querySelector('.section-SEARCH-m')
        const searchBtn = document.querySelector('.date-competition-selection .searchBtn')
        // const clearBtn =  document.querySelector('.section-SEARCH-m .clearSearchResultBtn')

        if(e?.target?.className?.includes('clearSearchResultBtn') ){
            setInputFocus(false)
            setShowMobileInputBox(true)
            return
        }
        if(searchNodeM) {
            if(checkParentNode(e.target, searchNodeM)){
                setInputFocus(true)
                return
            }
            if(checkParentNode(e.target, searchBtn)){
                setInputFocus(false)
                return
            }
        }else{
            const searchNode = document.querySelector('.section-SEARCH')
            if(checkParentNode(e.target, searchNode)){
                setInputFocus(true)
                return
            }
        }
        setTimeout(()=>{
            setShowMobileInputBox(false)
        },800)
        setInputFocus(false)
    }

    const checkParentNode = (childNode, parentNode) =>{
        while(childNode && childNode !== document.body){
            if(childNode === parentNode){
                return true
            }
            childNode = childNode?.parentElement
        }
        return false
    }

    const onFocusSearchInput = (e) => {
        if (isMobileSize) {
            e.preventDefault();
            e.target.setAttribute('data-init', 1);
            e.target.setSelectionRange(10, 10);
            setTimeout(function () {
                e.target.setSelectionRange(10, 10);
            });
        }
        setInputFocus(true)
    }

    const handleClickTeamName = (team) =>{
        if(!team || !team.label || !team.label.trim()){
            clearSearchResults()
            return
        }
        setFBSearchHistory({
            ...team,
            label: team.label.trim()
        })
        setSearchItem(team)
        setSearchText(team.label)
        setIsClearSearchResultBtn(true)
        setShowSearchHistory(false)
        setInputFocus(false)
        const filterTeam = allTeamList.filter(teamItem => teamItem.label.toLowerCase().includes(team.label.toLowerCase().trim()))
        const matchIdList = matches?.reduce((array, match) => {
            if(team.type === 'ALL' ){
                if(filterTeam.find(teamItem => (match.homeTeam.name_en == teamItem.name_en || match.awayTeam.name_en == teamItem.name_en || match.id === teamItem.matchId || match.tournament.code === teamItem.code ))){

                    array.push(match.id)
                }

            }else if(team.type === 'TEAM' && (match.homeTeam.name_en == team.name_en || match.awayTeam.name_en == team.name_en)){
                array.push(match.id)
            }
            else if(team.type === 'FRONTENDID' && match.id === team.matchId){
                array.push(match.id)
            }
            else if(team.type === 'TOURN' && match.tournament.code === team.code ){
                array.push(match.id)
            }
            return array
        },[]) || []
        contentRef.current.isLoading = true
        contentRef.current.selection.value = matchIdList
        contentRef.current.pageNum = 1
        loadMatchQuery({ page: contentRef.current.page, matchIds: matchIdList });
    }

    const handleClickDeleteHistoryBtn = (team,e) =>{
        deleteFBSearchHistory(team)
        let fbSearchHistory = getFBSearchHistory()
        if(searchText){
            fbSearchHistory = fbSearchHistory.filter(team => team.label.toLowerCase().includes(searchText.toLowerCase().trim()))
            const filterTeam = allTeamList.filter(team => team.label.toLowerCase().includes(searchText.toLowerCase().trim()) && !fbSearchHistory.find(i => i.label === team.label))
            const newTeamList = [...fbSearchHistory, ...filterTeam]
            setSearchResult(newTeamList)
        }else{
            setSearchResult(fbSearchHistory)
        }
        if(fbSearchHistory.length > 0 && !searchText){
            setShowSearchHistory(true)
        }else{
            setShowSearchHistory(false)
            if(!searchText){
                setInputFocus(false)
            }
        }
        e.stopPropagation()
    }

    const clearSearchResults = () =>{
        if(!updateCheckedLines()) return
        setSearchText('')
        setSearchItem(null)
        setIsClearSearchResultBtn(false)
        setInputFocus(false)
        searchAllMatch()
    }

    const handleClickClearResults = () => {
        if(isClearSearchResultBtn){
            if(!updateCheckedLines()) return
            searchAllMatch()
        }
        setSearchText('')
        setSearchItem(null)
        setIsClearSearchResultBtn(false)
        const searchInputNodeM = document.querySelector('.section-SEARCH-m .fbSearchInput')
        if(searchInputNodeM){
            searchInputNodeM.focus()
        }
    }

    const handleClickSearchResultMask = (e) =>{
        if(e.target.className === 'searchResultsListMask'){
            setInputFocus(false)
        }

    }

    const handleClickViewAllMatch = () => {
        clearSearchResults()
    }

    const updateCheckedLines = () => {
        if(!content?.config.MixAllUpPages.includes(content?.page))
        {
            let checkedLinesList = content?.oddsSelection?.filter((item) => {
                return item.checked
            })
            if(checkedLinesList.length > 0) {
                if(!confirm(t('LB_FB_UNSAVE_BET_ALERT'))){
                    return false;
                } else {
                    contentRef.current?.oddsSelection?.forEach(item=>{
                        item.checked = false
                    })
                }
            }
        }
        return true;
    }

    const searchAllMatch = () => {
        // const matchIdList = matches.map(match => match.id)
        contentRef.current.isLoading = true
        contentRef.current.selection.value = []
        contentRef.current.pageNum = 1
        setContent({...contentRef.current})
        if (contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)) {

            contentRef.current.matchData = { matches: contentRef.current.listData.matches };
            contentRef.current.isLoading = false;
            contentRef.current.page = 'MIXALLUP';
            contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
            contentRef.current.isAllUpDtls = false;
            setContent({ ...contentRef.current });
            return
        }
        loadMatchQuery({ page: contentRef.current.page, startIndex:1, endIndex: contentRef.current.config.MaxPaginationLimit});
    }



    return {
        activeSearch,
        searchText,
        showSearchHistory,
        inputFocus,
        searchResults,
        showMobileInputBox,
        searchItem,
        handleClickViewAllMatch,
        handleClickClearResults,
        onKeyDownEnterKey,
        setShowMobileInputBox,
        setSearchItem,
        setSearchText,
        setActiveSearch,
        onInputSearchText,
        onBlurSearchInput,
        onFocusSearchInput,
        handleClickTeamName,
        handleClickDeleteHistoryBtn,
        clearSearchResults,
        handleClickSearchResultMask,
        searchAllMatch,
        clearSearchText
    }
}

export const filterMatches = (matches=[]) => {

    const teamObj = matches.reduce((curObj,next)=>{
        const hTeam = next.homeTeam
        const aTeam = next.awayTeam
        const tourn = next.tournament || {}
        curObj[next.frontEndId] = {
            label: `${next.frontEndId}`,
            frontEndId: next.frontEndId,
            matchId: next.id,
            type: 'FRONTENDID'
        }
        if(tourn.code && !curObj[tourn.code]){
            curObj[tourn.code] ={
                ...tourn,
                label: `${tourn.name_en} (${tourn.name_ch})`,
                type: 'TOURN'
            }
        }
        if(!curObj[hTeam.name_en]){
            curObj[hTeam.name_en] = {
                ...hTeam,
                label: `${hTeam.name_en} (${hTeam.name_ch})`,
                type: 'TEAM'
            }
        }
        if(!curObj[aTeam.name_en]){
            curObj[aTeam.name_en] = {
                ...aTeam,
                label: `${aTeam.name_en} (${aTeam.name_ch})`,
                type: 'TEAM'
            }
        }
        return curObj
    },{})
    const teamList = Object.entries(teamObj).map(item => item[1]).sort((a,b)=> a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

    return teamList
}

export const getFBSearchHistory = () => {
    const searchHistoryCookie = document.cookie.split('; ').find(item => item.split('=')[0] === 'fbSearchHistory')
    const searchHistory = searchHistoryCookie ? JSON.parse(decodeURIComponent(searchHistoryCookie.split('=')[1])) : []
    return searchHistory.map(item => ({...item, isHistory:true}))
}

export const setFBSearchHistory = (newItem) => {
    let oldSearchHistory = getFBSearchHistory()
    oldSearchHistory = oldSearchHistory.filter(item => item.label !== newItem.label)
    if(oldSearchHistory.length === 5 ){
        oldSearchHistory.length = 4
    }
    oldSearchHistory.unshift(newItem)
    Cookies.setData('fbSearchHistory', JSON.stringify(oldSearchHistory) )
}

export const deleteFBSearchHistory= (delItem) =>{
    let oldSearchHistory = getFBSearchHistory()
    oldSearchHistory = oldSearchHistory.filter(item => item.label !== delItem.label)
    Cookies.setData('fbSearchHistory', JSON.stringify(oldSearchHistory))
}

export const checkMatchBySearchparams = (match, params) => {
    if(!match || !params) return false
    const valueList = filterMatches([match])
    const filterList = valueList.filter(teamItem => teamItem.label.toLowerCase().includes(params.label.toLowerCase().trim()))

    if(params.type === 'ALL'){
        if(filterList.find(item => (match.homeTeam.name_en == item.name_en || match.awayTeam.name_en == item.name_en || match.id === item.matchId || match.tournament.code === item.code ))){

            return true
        }
    }else if(params.type === 'TEAM' && (match.homeTeam.name_en == params.name_en || match.awayTeam.name_en == params.name_en)){
        return true
    }
    else if(params.type === 'FRONTENDID' && match.id === params.matchId){
        return true
    }
    else if(params.type === 'TOURN' && match.tournament.code === params.code ){
        return true
    }
    return false
}