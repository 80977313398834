import React, { useState, useEffect, useCallback, memo } from 'react';
import { useWindowSize } from '../../CommonHooks';
import './toast.scss';

const ToastContainer = ({ style, children }) => {
    return (
        <div className="main-content toast-fixed" style={style}>
            <div className="left-content reset">
                <div className="toast-con">{children}</div>
            </div>
            <div className="betslip-container reset"></div>
        </div>
    );
};

const ToastItem = ({ state = 'successful', message = 'successful', showTime, id, onExit }) => {
    const [show, setShow] = useState(true);
    useEffect(() => {
        let ref;
        if (showTime) {
            ref = setTimeout(() => {
                setShow(false);
            }, showTime);
        }
        return () => {
            clearTimeout(ref);
        };
    }, []);

    useEffect(() => {
        if (show === false) {
            onExit(id);
        }
    }, [show, onExit]);

    return show ? (
        <div className={`toastResultMeassage successful`} style={{ '--showTime': `${showTime / 1000}s` }}>
            {state === 'successful' && (
                <>
                    <div className="icon-status-accepted toastMsgIcon"></div>
                    <span className="successfulText">{message}</span>
                </>
            )}
            {state === 'warning' && (
                <>
                    <div className="icon-status-warning toastMsgIcon"></div>
                    <span className="successfulText rejectColor">{message}</span>
                </>
            )}
            {state === 'rejected' && (
                <>
                    <div className="icon-status-rejected toastMsgIcon"></div>
                    <span className="successfulText rejectColor">{message}</span>
                </>
            )}
        </div>
    ) : null;
};

const MemoToastItem = memo(ToastItem);

const Toast = () => {};

export default Toast;

const useToast = ({ style = {}, max = Infinity, showTime = 3000 } = {}) => {
    const [toastItem, setToastItem] = useState([]);

    const onExit = useCallback(
        (key) => {
            setToastItem([
                ...toastItem.filter((i) => {
                    return String(i.key) != key;
                })
            ]);
        },
        [toastItem]
    );

    const toastApi = useCallback(
        ({ state = 'successful', message = 'successful' } = {}) => {
            if (toastItem.length < max) {
                const key = Math.random();
                setToastItem([
                    ...toastItem,
                    {
                        showTime,
                        state,
                        message,
                        key,
                        id: key
                    }
                ]);
            }
        },
        [toastItem]
    );
    const { isMobileSize } = useWindowSize();
    return [
        toastApi,
        <ToastContainer
            style={{
                ...style
            }}
        >
            {toastItem.map((item) => (
                <MemoToastItem {...item} onExit={onExit} />
            ))}
        </ToastContainer>
    ];
};

Toast.useToast = useToast;

export { Toast };

