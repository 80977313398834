import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isLocalMeeting } from '../../Common/home-common';
import { lblBr } from '../../Common/GlobalFunc';
import { RacingContext } from '../../Home/RacingPage';
import { getPwinTimes } from './RCOddsTableBase';

const RCOddsHeaderM = (props) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);

    const isLocal = () => {
        return isLocalMeeting(context.content.venue);
    }

    const [pTime0, pTime1, winTime] = getPwinTimes(context);

    let cssClass = "rc-odds-table-header-m";
    if (props.raceStaticHeader != null) {
        cssClass = "rc-odds-table-header-m evenbg cwin-table-container";
    }

    return (
        <div className={cssClass}>
            {props.columns.raceNo ? <div className="no">{t('LB_RC_ODDS_TABLE_RACENO')}</div> : null}
            {props.columns.no ? <div className="no">{t('LB_RC_ODDS_TABLE_NO')}</div> : null}
            {props.columns.banker1 ? <div className="leg">{props.lbl.banker1}</div> : null}
            {props.columns.banker2 ? <div className="leg">{props.lbl.banker2}</div> : null}
            {props.columns.banker3 ? <div className="leg">{props.lbl.banker3}</div> : null}
            {props.columns.leg ? <div className="leg">{props.lbl.leg}</div> : null}
            {props.columns.horseName ? <div className="horseName">{t('LB_RC_ODDS_TABLE_HRNAME')}</div> : null}
            {props.columns.rcNoteM && isLocal() ? <div className="RCNOTE">{lblBr(t('LB_RC_ODDS_TABLE_RCNOTE_BR'))}</div> : null}
            {props.columns.pwin0 ? <td className="pwinCol pwinCol-m" colSpan={3}>
                <div className="pwinheader">{t('LB_RACE_PWIN')}</div>
                <div className='pwinColContainer'>
                    <div>{pTime0}</div>
                    {props.columns.pwin1 ? <div >{pTime1}</div> : null}
                    <div className="win">{winTime}</div>
                </div>
                </td> :null }
            {props.columns.win && !props.columns.pwin0 ? <div className=" win">{props.raceStaticHeader ? t('LB_ODDS'): t('LB_RC_ODDS_TABLE_WIN')}</div> : null}
            {props.columns.place ? <div className="place">{t('LB_RC_ODDS_TABLE_Place')}</div> : null}
            {props.columns.wp ? <div className="wp">{t('LB_RC_ODDS_TABLE_WINPlace')}</div> : null}
            {props.columns.draw && props.columns.standby ? <div className="draw">{t('LB_RC_ODDS_TABLE_DRAW')}</div> : null}
            {props.columns.wt && props.columns.standby ? <div className="wt">{t('LB_RC_ODDS_TABLE_WT')}</div> : null}
            <div className="any"></div>
        </div>
    )
}
export default RCOddsHeaderM;