import React, { useRef, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../Common/DateRangePicker'
import ReactRadio from '../../Common/ui-components/ReactRadio'
import { FBDropDownSearch } from './FBDropDownSearch'
import { isParentNode } from '../../BetSlip/BetSlipBase'
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { useWindowSize } from '../../Common/CommonHooks';
import { scrollTo } from '../../Common/GlobalFunc';
import { GlobalContext } from '../../../StandardPage';

const ResultSearchBox = ({ searchParams,datePickerKey, isOpen, setIsOpen, onChangeSearchParams, handleClickSearchBtn, windowSize, teamList, showRadioButton, prevSelectVal, setPrevSelectVal }, ref) => {
    // const [isOpen, setIsOpen] = useState(false)
    const { t, i18n } = useTranslation()
    const searchTextFocusRef = useRef(false);
    const [searchText, setSearchText] = useState('')
    const [inputFocus, setInputFocus] = useState(false)
    const [searchResults, setSearchResult] = useState([])
    const [newTeamList, setNewTeamList] = useState([])
    const { isMobileSize } = useWindowSize();
    const {sessionServerTimeStamp} = useContext(GlobalContext);

    useEffect(() => {
        const handleDDClick = (e) => {
            if (!document?.getElementById('FBFilterSelectDrd')) { return; }
            const toggleEle = isParentNode(['dropdown-toggle'], e.target)
            if (searchTextFocusRef.current && !toggleEle) {
                const isSearchText = isParentNode(['resultSearchText'], e.target)
                const isScrollBar = isParentNode(['fbSearchScroll'], e.target)
                const isSearchItem = isParentNode(['filterItem'], e.target)
                const isCloseBtn = document.querySelector('.msgInboxClose.clearSearchResultBtn') === e.target
                if ((isScrollBar && !isSearchItem) || isCloseBtn) {
                    if (isCloseBtn) {
                        setSearchText('')
                    }
                    const searchInputNodeM = document.querySelector('#FBFilterSelectDrd .fbSearchInput')
                    if (searchInputNodeM) {
                        searchInputNodeM.focus()
                    }
                } else if (!isSearchText) {
                    setInputFocus(false);
                }
            }
        }
        const dropdownEle = document.querySelector('#root');
        dropdownEle.addEventListener('click', handleDDClick)
        return () => {
            dropdownEle.removeEventListener('click', handleDDClick)
        }
    }, [])

    useEffect(() => {
        if (teamList?.length > 0 && newTeamList.length == 0) {
            const newArr = teamList.map(item => {
                const teamName = `${item.name_en}(${item.name_ch})`
                return { ...item, teamName }
            });
            setSearchResult(newArr);
            setNewTeamList(newArr);
        }
    }, [teamList])



    const handleClickExpand = () => {
        setIsOpen && setIsOpen(!isOpen)
    }

    const handleClickTeam = (id) => {
        onChangeSearchParams({
            teamId: id
        })
    }

    const handleClickRadio = (type) => {
        onChangeSearchParams({
            searchType: type
        })
    }
    const onOpenCalendar = () => {
        onChangeSearchParams({
            searchType: 'DATE'
        })
    }

    const onInputSearchText = (e) => {
        let value = e?.target?.value || ''
        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
            if (e.nativeEvent.data != null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute('data-init', 0);
        }
        setSearchText(value)
    }

    const onFocusSearchInput = () => {
        setInputFocus(true)
        searchTextFocusRef.current = true;
    }
    const onBlurSearchInput = (e) => {
    }

    const toggleOnOff = () => {
        setInputFocus(!inputFocus);
        searchTextFocusRef.current = !inputFocus;
        scrollTo(0, 80)
    }

    const handleClickTeamName = (team) => {
        if (!team || !team.id) return;
        setPrevSelectVal(team);
        handleClickTeam(team.id);
        setSearchText('');
        onBlurSearchInput();
    }

    const handleClickClearResults = (e) => {
    }

    let teamName = t("LB_FB_RESULT_ALL_TEAM");
    if (searchParams.teamId !== '0') {
        const selectedTeam = teamList?.filter(x => x.id === searchParams.teamId)[0];
        teamName = i18n.language === 'en' ? selectedTeam?.nameE : `${selectedTeam?.nameC} (${selectedTeam?.nameE})`;
    }

    return <div className='fb-results-searchbox' id='SearchBox'>
        <header className={`fb-search-title ${!isOpen ? "searchBoxExpand" : ''}`} onClick={handleClickExpand}>
            <div className='arrow-area'><ArrowIcon  className='hueRotate' direction='down' change={isOpen} changeDirection='up' size={20} /></div>
            <span>{t('LB_M6_RESULTS_SEARCH')}</span>
            <div className='m-arrow-area'><ArrowIcon  className='hueRotate' direction='down' change={isOpen} changeDirection='up' size={20} /></div>
        </header>
        <div className={`fb-results-searchbox-body ${isOpen ? 'searchbox-body-show' : 'searchbox-body-hide'}`}>

            <div className='fb-results-searchbox-content' >
                <div className='fb-results-searchbox-form'>

                    <div className='fb-results-searchbox-select'>
                        {showRadioButton && <div className='df searchBoxRadioContainer '>
                            <ReactRadio type='football' onChange={() => handleClickRadio('LAST10')} checked={searchParams.searchType === 'LAST10'} label={t('LB_FB_DHCRES_LAST10')} />
                            <ReactRadio type='football' onChange={() => handleClickRadio('DATE')} checked={searchParams.searchType === 'DATE'} label={t('LB_FB_DHCRES_DATE')} />
                        </div>}
                        <div className='date_range_football'>
                            <div>
                                ({t('LB_FB_RESULT_SEARCH_TIPS')})
                            </div>
                            <DateRangePicker
                                onChangeDate={onChangeSearchParams}
                                windowSize={windowSize}
                                calendarOpened={onOpenCalendar}
                                defaultDate={{ ...searchParams }}
                                minDate={new Date('2003-1-1')}
                                showMonthAndYearDropdown
                                maxDate={new Date(sessionServerTimeStamp)}
                                maxSelectDays={31}
                                className='date_range_picker '
                                topValueLabel={t('LB_FB_RESULT_SEARCH_TIPS')}
                                key={datePickerKey}
                            />
                        </div>
                        {teamList && <FBDropDownSearch
                            searchText={searchText}
                            searchResults={searchResults}
                            onInputSearchText={onInputSearchText}
                            onClickTeamName={handleClickTeamName}
                            prevSelectVal={prevSelectVal}
                            searchParams={searchParams}
                            inputFocus={inputFocus}
                            setInputFocus={setInputFocus}
                            onBlurSearchInput={onBlurSearchInput}
                            onFocusSearchInput={onFocusSearchInput}
                            toggleOnOff={toggleOnOff}
                            clearSearchResults={handleClickClearResults}
                        />}
                    </div>
                </div>
                <button className='search-btn cta_fb' onClick={handleClickSearchBtn}>
                    {t('LB_M6_RESULTS_SEARCH')}
                </button>
            </div>
        </div>
    </div>
}

export default ResultSearchBox