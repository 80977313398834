import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../StandardPage';

import $ from 'jquery';

export const AllupCheckbox = ({ id, val }) => {

    const globalContext = useContext(GlobalContext);
    
    useEffect(()=>{
        if(globalContext.globalState.betType.includes('ALUP')){
            globalContext.updateGlobalState({ allupBoxChecked: true })
        }else{
            globalContext.updateGlobalState({ allupBoxChecked: false })
        }

    },[globalContext.globalState.betType])

    const onClickCheckBox = (isEnabled, targetPage) => {
        if (!isEnabled) {
            return false;
        }

        if ( !globalContext.globalRef.current.allupBoxChecked ) {
            globalContext.racingPageFunc.current.handleAllUpClick(null, true, targetPage);
            // globalContext.updateGlobalState({ allupBoxChecked: true });
        }
        else {
            globalContext.racingPageFunc.current.handleAllUpClick(null, false, targetPage);
            // globalContext.updateGlobalState({ allupBoxChecked: false });
        }
    }


    const onClickCWACheckBox = () => {

        let allupBtn = $("#allupBtn")[0];
        if (allupBtn.disabled)
            return;

        document.getElementById("cwaallupCheckbox").click();
    }

    const onClickCWAALLUPCheckBox = () => {

        let allupBtn = $("#allupBtn")[0];
        if (allupBtn.disabled)
            return;

        document.getElementById("cwaalallupCheckbox").click();
    }

    const btnProps={ globalContext, id, onClickCWACheckBox, onClickCWAALLUPCheckBox, onClickCheckBox }

    return (
        <>

            <span className={`cb-allup-container`}>
                <AllupButton {...btnProps}/>
            </span>

        </>
    );
}
const AllupButton = ({globalContext, id, onClickCWACheckBox, onClickCWAALLUPCheckBox, onClickCheckBox}) => {
    const { t, i18n } = useTranslation();

    if (globalContext.globalState.product === 'racing' && globalContext.globalState.mtg) {
        let isEnabled = globalContext.globalState.mtg.totalNumberOfRace > 1;

        if (globalContext.globalState.betType === 'CWA') {
            return <div className='cb-allup-container-cwa'>
                    <div>
                        <label>{t('LB_RC_ALLUP')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id={id} disabled={!isEnabled} />
                        <span id={`cwa-${id}-span`} onClick={onClickCWACheckBox}></span>
                        <label onClick={onClickCWACheckBox}>{t('LB_RC_INDIVIDUAL_RACE')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id={id} disabled={!isEnabled} />
                        <span id={`cwaall-${id}-span`} onClick={onClickCWAALLUPCheckBox}></span>
                        <label onClick={onClickCWAALLUPCheckBox}>{t('LB_RC_ALL_RACE')}</label>
                    </div>
                </div>
        } else {
            let targetPage = '';
            switch (globalContext.globalState.betType) {
                case "WP":
                    targetPage = "WPALUP";
                    break;
                case "WPALUP":
                    targetPage = "WP";
                    break;
                case "WPQ":
                    targetPage = "WPQALUP";
                    break;
                case "WPQALUP":
                    targetPage = "WPQ";
                    break;
                case "FCT":
                    targetPage = "FCTALUP";
                    break;
                case "FCTALUP":
                    targetPage = "FCT";
                    break;
                case "TRI":
                    targetPage = "TRIALUP";
                    break;
                case "TRIALUP":
                    targetPage = "TRI";
                    break;
                case "JTCOMBO":
                    targetPage = "JTCOMBO";
                    isEnabled = globalContext.globalState.jtcPara.length > 0;
                    break;
            }

            return <>
                <input type="checkbox" id={id} disabled={!isEnabled} checked={globalContext.globalRef.current.allupBoxChecked} />
                <span id={`${id}-span`} onClick={()=> { onClickCheckBox (isEnabled, targetPage) }}></span>
                <label onClick={()=> { onClickCheckBox (isEnabled, targetPage) }}>{t('LB_RC_ALLUP')}</label>
            </>
        }
    }
    return null
}